import { formatDate } from "@/shared/libs/util_func";
import NotificationRes from "@/types/res/NotificationRes";
type NotificationDataState = {
  entities: {
    notifications: { [key: number]: NotificationRes };
  };
  result: number[];
};
export type NotificationState = {
  isLoading: boolean;
  unreadCount: number;
  pageToken: string | null;
} & NotificationDataState;
const initialState = {} as NotificationState;

export default function notificationReducer(
  state = initialState,
  action: {
    type: string;
    data: NotificationDataState;
    isLoading: boolean;
    unreadCount: number;
    pageToken: string | null;
    notificationId: number;
  },
) {
  switch (action.type) {
    case "GET_NOTIFICATIONS_BEGIN":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_NOTIFICATIONS_SUCCESS":
      return {
        ...state,
        entities: {
          notifications: {
            ...state.entities?.notifications,
            ...action.data.entities.notifications,
          },
        },
        result: [...(state.result || []), ...action.data.result],
        isLoading: false,
        unreadCount: action.unreadCount,
        pageToken: action.pageToken,
      };
    case "GET_NOTIFICATIONS_FAILURE":
      return {
        ...state,
        isLoading: false,
      };
    case "READ_ALL_NOTIFICATIONS_SUCCESS": {
      let notifications: { [key: number]: NotificationRes } = {};
      Object.values(state.entities?.notifications || {}).forEach(
        (notification) => {
          if (notification.readAt === null) {
            notifications = {
              ...notifications,
              [notification.id]: {
                ...notification,
                readAt: formatDate(new Date()),
              },
            };
          } else {
            notifications = {
              ...notifications,
              [notification.id]: notification,
            };
          }
        },
      );
      return {
        ...state,
        entities: {
          notifications: notifications,
        },
        unreadCount: 0,
      };
    }
    case "READ_NOTIFICATION_SUCCESS": {
      return {
        ...state,
        entities: {
          notifications: {
            ...state.entities.notifications,
            [action.notificationId]: {
              ...state.entities.notifications[action.notificationId],
              readAt: formatDate(new Date()),
            },
          },
        },
        unreadCount: state.unreadCount - 1,
      };
    }
    case "UNREAD_NOTIFICATION_SUCCESS": {
      return {
        ...state,
        entities: {
          notifications: {
            ...state.entities.notifications,
            [action.notificationId]: {
              ...state.entities.notifications[action.notificationId],
              readAt: null,
            },
          },
        },
        unreadCount: state.unreadCount + 1,
      };
    }
    default:
      return state;
  }
}
