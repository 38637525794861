import KpiProjectRes from "@/types/res/KpiProjectRes";

type KpiProjectDataState = {
  entities: {
    kpiProjects: { [key: number]: KpiProjectRes };
  };
  result: number[];
};
export type KpiProjectState = {
  openedChannelIds: number[];
} & KpiProjectDataState;
const initialState = {} as KpiProjectState;
export default function kpiProjectReducer(
  state = initialState,
  action: {
    type: string;
    data: KpiProjectDataState;
    openedChannelIds: number[];
    channelId: number;
  },
) {
  switch (action.type) {
    case "GET_KPI_PROJECTS_SUCCESS":
      return { ...state, ...action.data };
    case "SET_KPI_USER_GROUP":
      return { ...state, openedChannelIds: action.openedChannelIds };
    case "CLOSE_KPI_PROJECTS": {
      let kpiProjects: { [key: number]: KpiProjectRes } = {};
      Object.values(state?.entities?.kpiProjects || {}).forEach((project) => {
        if (project.channel.id === action.channelId) {
          kpiProjects = { ...kpiProjects, [project.id]: project };
        }
      });
      return {
        ...state,
        result: Object.keys(kpiProjects).map(Number),
        entities: {
          kpiProjects: { ...kpiProjects },
        },
      };
    }
    default:
      return state;
  }
}
