export type ContractState = {
  isChangingPlan: boolean;
  isCanceling: boolean;
  isPayingPendingSubscription: boolean;
  isAddingStorage: boolean;
  stripePendingPayments: AsyncData<StripePendingPayment[]> | null;
  stripeStripeInvoiceMap: AsyncData<{ [id: number]: StripeInvoice }>;
};

interface AsyncData<T> {
  isLoading: boolean;
  data?: T;
  error?: Error;
}

interface StripePendingPayment {
  id: number;
  isPayed: boolean;
  url?: string;
}
interface StripeInvoice {
  periodEnd: number;
  periodStart: number;
  hostedInvoiceUrl: string;
  amountDue: number;
  currency: string;
}

const initialState: ContractState = {
  isChangingPlan: false,
  isCanceling: false,
  isPayingPendingSubscription: false,
  isAddingStorage: false,
  stripePendingPayments: null,
  stripeStripeInvoiceMap: { isLoading: false, data: {} },
};

type Action =
  | { type: "PAY_STANDARD_PLAN_FAILURE" }
  | { type: "PAY_STANDARD_PLAN" }
  | { type: "PAY_STANDARD_PLAN_SUCCESS" }
  | { type: "CANCEL_PLAN" }
  | { type: "CANCEL_PLAN_SUCCESS" }
  | { type: "CANCEL_PLAN_FAILURE" }
  | { type: "PAY_PENDING_SUBSCRIPTION_FAILURE" }
  | { type: "PAY_PENDING_SUBSCRIPTION" }
  | { type: "PAY_PENDING_SUBSCRIPTION_SUCCESS" }
  | { type: "ADD_STORAGE_CAPACITY" }
  | { type: "ADD_STORAGE_CAPACITY_SUCCESS" }
  | { type: "ADD_STORAGE_CAPACITY_FAILURE" }
  | {
      type: "PUT_STRIPE_PENDING_PAYMENTS";
      result: AsyncData<StripePendingPayment[]>;
    }
  | {
      type: "PUT_STRIPE_PENDING_PAYMENT";
      isLoading: boolean;
      error?: Error;
      data?: StripeInvoice;
    };

export const contractReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case "PAY_STANDARD_PLAN":
      return {
        ...state,
        isChangingPlan: true,
      };
    case "PAY_STANDARD_PLAN_SUCCESS":
      return {
        ...state,
        isChangingPlan: false,
      };
    case "PAY_STANDARD_PLAN_FAILURE":
      return {
        ...state,
        isChangingPlan: false,
      };
    case "CANCEL_PLAN":
      return {
        ...state,
        isCanceling: true,
      };
    case "CANCEL_PLAN_SUCCESS":
      return {
        ...state,
        isCanceling: false,
      };
    case "CANCEL_PLAN_FAILURE":
      return {
        ...state,
        isCanceling: false,
      };
    case "PAY_PENDING_SUBSCRIPTION_FAILURE":
      return {
        ...state,
        isPayingPendingSubscription: false,
      };
    case "PAY_PENDING_SUBSCRIPTION_SUCCESS":
      return {
        ...state,
        isPayingPendingSubscription: false,
      };
    case "PAY_PENDING_SUBSCRIPTION":
      return {
        ...state,
        isPayingPendingSubscription: true,
      };
    case "ADD_STORAGE_CAPACITY":
      return {
        ...state,
        isAddingStorage: true,
      };
    case "ADD_STORAGE_CAPACITY_SUCCESS":
      return {
        ...state,
        isAddingStorage: false,
      };
    case "ADD_STORAGE_CAPACITY_FAILURE":
      return {
        ...state,
        isAddingStorage: false,
      };
    case "PUT_STRIPE_PENDING_PAYMENTS":
      return {
        ...state,
        stripePendingPayments: action.result,
      };
    case "PUT_STRIPE_PENDING_PAYMENT": {
      const newMap = action.data
        ? {
            ...state.stripeStripeInvoiceMap,
            isLoading: action.isLoading,
            data: { ...state.stripeStripeInvoiceMap.data, ...action.data },
          }
        : { ...state.stripeStripeInvoiceMap, isLoading: action.isLoading };
      if (action.error) newMap.error = action.error;

      return {
        ...state,
        stripeStripeInvoiceMap: newMap,
      };
    }
    default:
      return state;
  }
};
