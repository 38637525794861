import EquipmentRes from "@/types/res/EquipmentRes";

export type EquipmentState = {
  entities: {
    equipments: { [key: number]: EquipmentRes };
  };
  result: number[];
};
const initialState = {} as EquipmentState;
export default function equipmentReducer(
  state = initialState,
  action: {
    type: string;
    data: EquipmentState;
    equipmentId: number;
  },
) {
  switch (action.type) {
    case "GET_EQUIPMENTS_SUCCESS":
      return action.data;
    case "GET_EQUIPMENT_SUCCESS": {
      return {
        ...state,
        entities: {
          equipments: {
            ...state.entities?.equipments,
            ...action.data.entities.equipments,
          },
        },
        result: action.data.result,
      };
    }
    case "CREATE_EQUIPMENT_SUCCESS": {
      return {
        ...state,
        entities: {
          equipments: {
            ...state.entities.equipments,
            ...action.data.entities.equipments,
          },
        },
        result: [...state.result, ...action.data.result],
      };
    }
    case "UPDATE_EQUIPMENT_SUCCESS": {
      return {
        ...state,
        entities: {
          equipments: {
            ...state.entities.equipments,
            ...action.data.entities.equipments,
          },
        },
        result: [...state.result],
      };
    }
    case "ACTIVATE_EQUIPMENT_SUCCESS": {
      return {
        ...state,
        entities: {
          ...state.entities,
          equipments: {
            ...state.entities.equipments,
            [action.equipmentId]: {
              ...state.entities.equipments[action.equipmentId],
              status: "active",
            },
          },
        },
        result: state.result,
      };
    }
    case "INACTIVATE_EQUIPMENT_SUCCESS": {
      return {
        ...state,
        entities: {
          ...state.entities,
          equipments: {
            ...state.entities.equipments,
            [action.equipmentId]: {
              ...state.entities.equipments[action.equipmentId],
              status: "inactive",
            },
          },
        },
        result: state.result,
      };
    }
    default:
      return state;
  }
}
