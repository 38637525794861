import dayjs from "dayjs";
import ChannelRes from "@/types/res/ChannelRes";
import PlanBoxProjectRes from "@/types/res/PlanBoxProjectRes";
import UserRes from "@/types/res/UserRes";

type PlanData = {
  entities: {
    planBoxProjects: { [key: number]: PlanBoxProjectRes };
    users: { [key: number]: UserRes };
    channels: { [key: number]: ChannelRes };
  };
  result: number[];
};

export type PlanState = {
  isFetching: boolean;
  planBoxProjectsCount: number;
  startDate: dayjs.ConfigType;
  endDate: dayjs.ConfigType;
  kanbanCount: {
    [status: string]: number;
  };
} & PlanData;

const initialState = {} as PlanState;
export default function planReducer(
  state = initialState,
  action: {
    type: string;
    data: PlanData;
    id: number;
    ids: number[];
    object: { [key: string]: any };
    planBoxProjectsCount: number;
    status: string;
    startDate: dayjs.ConfigType;
    endDate: dayjs.ConfigType;
  },
) {
  switch (action.type) {
    case "GET_PLAN_BOX_PROJECTS_START":
      return {
        ...state,
        isFetching: true,
      };
    case "GET_PLAN_BOX_PROJECTS_SUCCESS": {
      return {
        ...state,
        ...action.data,
        isFetching: false,
      };
    }
    case "OPTIMISTIC_UPDATE_PLAN_BOX_SUCCESS": {
      return {
        ...state,
        entities: {
          ...state.entities,
          planBoxProjects: {
            ...state.entities.planBoxProjects,
            [action.id]: {
              ...state.entities.planBoxProjects[action.id],
              ...action.object,
            },
          },
        },
      };
    }
    case "GET_PLAN_BOX_PROJECT_SUCCESS": {
      return {
        ...state,
        entities: {
          ...state.entities,
          planBoxProjects: {
            ...state?.entities?.planBoxProjects,
            [action.id]: {
              ...state?.entities?.planBoxProjects?.[action.id],
              ...action?.data?.entities?.planBoxProjects?.[action.id],
            },
          },
        },
      };
    }
    case "GET_PLAN_BOX_PROJECT_FAILURE":
      return {
        ...state,
        isFetching: false,
      };
    case "GET_PLAN_BOX_PROJECTS_FAILURE":
      return {
        ...state,
        isFetching: false,
      };
    case "GET_PLAN_BOX_PROJECTS_COUNT_SUCCESS":
      return {
        ...state,
        planBoxProjectsCount: action.planBoxProjectsCount,
      };
    case "GET_PLAN_BOX_PROJECTS_COUNT_KANBAN_SUCCESS":
      return {
        ...state,
        kanbanCount: {
          ...state.kanbanCount,
          [action.status]: action.planBoxProjectsCount,
        },
      };
    case "POST_PLAN_BOX_PROJECT_SUCCESS": {
      return {
        ...state,
        entities: {
          ...state.entities,
          planBoxProjects: {
            ...state.entities.planBoxProjects,
            ...action.data.entities.planBoxProjects,
          },
        },
        result: [...action.data.result, ...state.result],
      };
    }
    case "CLONE_PROJECT_SUCCESS": {
      return {
        ...state,
        entities: {
          ...state.entities,
          planBoxProjects: {
            ...state.entities.planBoxProjects,
            ...action.data.entities.planBoxProjects,
          },
        },
        result: [...action.data.result, ...state.result],
      };
    }
    case "UPDATE_PLAN_BOX_PROJECTS_SUCCESS": {
      return {
        ...state,
        entities: {
          ...state.entities,
          planBoxProjects: {
            ...state?.entities?.planBoxProjects,
            [action.id]: {
              ...state?.entities?.planBoxProjects?.[action.id],
              ...action?.data?.entities?.planBoxProjects?.[action.id],
            },
          },
        },
      };
    }
    case "DELETE_PLAN_BOX_PROJECT_SUCCESS": {
      if (!state.entities) return state;
      const project = state.entities.planBoxProjects[action.id];
      const kanbanStatus = project.currentKanbanStatusProcess;
      delete state.entities.planBoxProjects[action.id];
      return {
        ...state,
        entities: {
          ...state.entities,
          planBoxProjects: state.entities.planBoxProjects,
        },
        result: state.result.filter((id) => action.id !== id),
        kanbanCount: {
          ...state.kanbanCount,
          [kanbanStatus]: state.kanbanCount[kanbanStatus]
            ? state.kanbanCount[kanbanStatus] - 1
            : 0,
        },
      };
    }
    case "ARCHIVE_PLAN_BOX_PROJECT_SUCCESS": {
      if (!state.entities) return state;
      action.ids.forEach((id) => {
        delete state.entities.planBoxProjects[id];
      });
      return {
        ...state,
        entities: {
          ...state.entities,
          planBoxProjects: state.entities.planBoxProjects,
        },
        result: state.result.filter((id) => !action.ids.includes(id)),
      };
    }
    case "SET_MONTH":
      return {
        ...state,
        startDate: action.startDate,
        endDate: action.endDate,
      };
    case "RESET_PLAN_BOX_PROJECTS": {
      return {
        ...state,
        entities: {
          ...state.entities,
          planBoxProjects: undefined,
        },
        result: [],
      };
    }
    default:
      return state;
  }
}
