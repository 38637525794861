type State = {
  sender: Sender;
};
const initialState: State = {
  sender: null,
};
export default function senderReducer(
  state = initialState,
  action: { type: string; data: Sender },
) {
  switch (action.type) {
    case "GET_SENDER_SUCCESS":
      return {
        ...state,
        sender: action.data,
      };
    default:
      return state;
  }
}
