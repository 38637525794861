export type DrawingState = {
  isPresent: boolean;
  base64: string;
  redoTrigger: boolean;
  undoTrigger: boolean;
  resetTrigger: boolean;
  submitTrigger: boolean;
};
const initialState: DrawingState = {
  isPresent: false,
  base64: "",
  redoTrigger: false,
  undoTrigger: false,
  resetTrigger: false,
  submitTrigger: false,
};
export default function drawingReducer(
  state = initialState,
  action: {
    type: string;
    base64: string;
  },
) {
  switch (action.type) {
    case "SET_IS_PRESENT":
      return {
        ...state,
        isPresent: true,
      };
    case "UNSET_IS_PRESENT":
      return {
        ...state,
        isPresent: false,
      };
    case "SET_BASE_64":
      return {
        ...state,
        base64: action.base64,
      };
    case "UNDO_DRAWING":
      return {
        ...state,
        undoTrigger: true,
      };
    case "REDO_DRAWING":
      return {
        ...state,
        redoTrigger: true,
      };
    case "REVERSE_UNDO_TRIGGER":
      return {
        ...state,
        undoTrigger: false,
      };
    case "REVERSE_REDO_TRIGGER":
      return {
        ...state,
        redoTrigger: false,
      };
    case "RESET_DRAWING":
      return {
        ...state,
        isPresent: false,
        base64: "",
        resetTrigger: true,
      };
    case "CREATE_REVIEW_COMMENT_SUCCESS":
      return {
        ...state,
        isPresent: false,
        base64: "",
        resetTrigger: true,
        submitTrigger: true,
      };
    case "TEMPORARY_CREATE_REVIEW_COMMENT_SUCCESS":
      // TODO: temporary削除完了後、このcase文を消すこと
      return {
        ...state,
        isPresent: false,
        base64: "",
        resetTrigger: true,
        submitTrigger: true,
      };
    case "REVERSE_SUBMIT_TRIGGER":
      return {
        ...state,
        submitTrigger: false,
      };
    case "REVERSE_RESET_TRIGGER":
      return {
        ...state,
        isPresent: false,
        resetTrigger: false,
      };
    default:
      return state;
  }
}
