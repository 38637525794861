import CreativeFileRes from "@/types/res/CreativeFileRes";

export type CreativeFileState = {
  entities: {
    creativeFiles: { [key: number]: CreativeFileRes };
  };
  result: number[];
  selectedReviewCommentVersionCreativeFileId: number;
  title: string;
  file: string;
  fileSize: number;
  originalFileUrl: string;
  youtubeVideoId: string;
  vimeoVideoId: string;
  textLink: string;
  thumbnail: string;
};

const initialState = {} as CreativeFileState;

export default function creativeFileReducer(
  state = initialState,
  action: {
    type: string;
    key: string;
    value: string;
    data: CreativeFileState;
    selectedReviewCommentVersionCreativeFileId: number;
  },
) {
  switch (action.type) {
    case "GET_LATEST_FILES_SUCCESS":
      return {
        ...state,
        entities: action.data.entities,
        result: action.data.result,
      };
    case "SET_REVIEW_COMMENT_VERSION_CREATIVE_FILE_ID":
      return {
        ...state,
        selectedReviewCommentVersionCreativeFileId:
          action.selectedReviewCommentVersionCreativeFileId,
      };
    case "CHANGE_CREATIVE_FILE_VALUE":
      return {
        ...state,
        [action.key]: action.value,
      };
    case "CLEAR_UPLOAD_DATA":
      return {
        ...state,
        title: "",
        file: "",
        fileSize: null,
        originalFileUrl: "",
        youtubeVideoId: "",
        vimeoVideoId: "",
        textLink: "",
        thumbnail: "",
      };
    default:
      return state;
  }
}
