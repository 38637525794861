import UserCustomViewRes from "@/types/res/UserCustomViewRes";

export type UserCustomViewState = {
  entities: {
    userCustomViews: {
      [key: number]: UserCustomViewRes;
    };
  };
  result: number[];
};
const initialState = {} as UserCustomViewState;
export default function userCustomViewReducer(
  state = initialState,
  action: {
    type: string;
    data: UserCustomViewState;
    id: number;
    userCustomView: UserCustomViewRes;
    sorts: number[];
  },
) {
  switch (action.type) {
    case "GET_USER_CUSTOM_VIEWS_SUCCESS": {
      return { ...action.data };
    }
    case "UPDATE_USER_CUSTOM_VIEW_SUCCESS": {
      const updateUserCustomView = { ...action.userCustomView };
      return {
        ...state,
        entities: {
          userCustomViews: {
            ...state.entities.userCustomViews,
            [action.id]: updateUserCustomView,
          },
        },
      };
    }
    case "UPDATE_USER_CUSTOM_VIEW_NAME_SUCCESS": {
      return {
        ...state,
        entities: {
          userCustomViews: {
            ...state.entities.userCustomViews,
            [action.id]: action.userCustomView,
          },
        },
      };
    }
    case "DESTROY_USER_CUSTOM_VIEW_SUCCESS": {
      delete state.entities.userCustomViews[action.id];
      return {
        ...state,
        entities: {
          userCustomViews: { ...state.entities.userCustomViews },
        },
        result: state.result.filter((id) => id !== action.id),
      };
    }
    case "VERSION_UP_PUBLIC_CUSTOM_VIEW_SUCCESS": {
      const userCustomView = state.entities.userCustomViews[action.id];
      userCustomView.version = userCustomView.version + 1;
      userCustomView.isSamePublicVersion = true;
      userCustomView.isSamePublicDefinition = true;
      return {
        ...state,
        entities: {
          userCustomViews: {
            ...state.entities.userCustomViews,
            [action.id]: userCustomView,
          },
        },
      };
    }
    case "REFLECT_PUBLIC_CUSTOM_VIEW_SUCCESS": {
      const userCustomView = state.entities.userCustomViews[action.id];
      userCustomView.version = userCustomView.version + 1;
      userCustomView.isSamePublicVersion = true;
      userCustomView.isSamePublicDefinition = true;
      return {
        ...state,
        entities: {
          userCustomViews: {
            ...state.entities.userCustomViews,
            [action.id]: userCustomView,
          },
        },
      };
    }
    case "VERSION_UP_PRIVATE_CUSTOM_VIEW_SUCCESS": {
      const userCustomView = state.entities.userCustomViews[action.id];
      userCustomView.version = userCustomView.version + 1;
      userCustomView.isSamePrivateVersion = true;
      userCustomView.isSamePrivateDefinition = true;
      return {
        ...state,
        entities: {
          userCustomViews: {
            ...state.entities.userCustomViews,
            [action.id]: userCustomView,
          },
        },
      };
    }
    case "REFLECT_PRIVATE_CUSTOM_VIEW_SUCCESS": {
      const userCustomView = state.entities.userCustomViews[action.id];
      userCustomView.version = userCustomView.version + 1;
      userCustomView.isSamePrivateVersion = true;
      userCustomView.isSamePrivateDefinition = true;
      return {
        ...state,
        entities: {
          userCustomViews: {
            ...state.entities.userCustomViews,
            [action.id]: userCustomView,
          },
        },
      };
    }
    case "TREAT_AS_TEAM_VIEW_SUCCESS": {
      return {
        ...state,
        entities: {
          userCustomViews: {
            ...state.entities.userCustomViews,
            [action.id]: action.userCustomView,
          },
        },
      };
    }
    case "TREAT_AS_USER_VIEW_SUCCESS": {
      return {
        ...state,
        entities: {
          userCustomViews: {
            ...state.entities.userCustomViews,
            [action.id]: action.userCustomView,
          },
        },
      };
    }
    case "INVITE_USER_USER_VIEW_SUCCESS": {
      return {
        ...state,
        entities: {
          userCustomViews: {
            ...state.entities.userCustomViews,
            [action.id]: action.userCustomView,
          },
        },
      };
    }
    case "INVITE_CHANNEL_USER_VIEW_SUCCESS": {
      return {
        ...state,
        entities: {
          userCustomViews: {
            ...state.entities.userCustomViews,
            [action.id]: action.userCustomView,
          },
        },
      };
    }
    case "UPDATE_USE_CUSTOM_VIEW_SORT_SUCCESS": {
      return {
        ...state,
        result: action.sorts,
      };
    }
    default:
      return state;
  }
}
