export type LoadingHandlerState = {
  isDisplay: boolean;
};
const initialState: LoadingHandlerState = {
  isDisplay: false,
};

export default function loadingHandlerReducer(
  state = initialState,
  action: { type: string },
) {
  switch (action.type) {
    case "HANDLE_LOADING_START":
      return {
        ...initialState,
        isDisplay: true,
      };
    case "HANDLE_LOADING_END":
      return initialState;
    default:
      return state;
  }
}
