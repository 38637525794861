import { today } from "@/shared/libs/util_func";
import ReviewCommentRes from "@/types/res/ReviewCommentRes";
import UserRes from "@/types/res/UserRes";

export type ReviewCommentState = {
  entities: {
    reviewComments: {
      [key: number]: ReviewCommentRes;
    };
  };
  result: number[];
  content: string;
  attachment: string;
  filename: string;
  checkFilter: boolean;
  page: number;
  isLoading: boolean;
};
const initialState = {} as ReviewCommentState;

export default function reviewCommentReducer(
  state = initialState,
  action: {
    type: string;
    key: string;
    value: string;
    data: ReviewCommentState;
    reviewCommentId: number;
    content: string;
    reviewReplyId: number;
    checkFilter: boolean;
    id: id;
    page: number;
    currentUser: UserRes;
  },
) {
  switch (action.type) {
    case "GET_REVIEW_COMMENTS_SUCCESS":
      return {
        ...state,
        entities: {
          reviewComments: action.data.entities.reviewComments,
        },
        result: action.data.result,
        isLoading: false,
      };
    case "GET_REVIEW_COMMENT_FAILURE":
      return {
        ...state,
        isLoading: false,
      };
    case "GET_ARCHIVED_REVIEW_COMMENTS_SUCCESS":
      return {
        ...state,
        entities: {
          reviewComments: action.data.entities.reviewComments,
        },
        result: action.data.result,
        isLoading: false,
      };
    case "ARCHIVE_REVIEW_COMMENT_SUCCESS":
      delete state.entities.reviewComments[action.reviewCommentId];
      return {
        ...state,
        entities: {
          ...state.entities,
          reviewComments: state.entities.reviewComments,
        },
        result: state.result.filter((id) => action.reviewCommentId !== id),
      };
    case "UNARCHIVE_REVIEW_COMMENT_SUCCESS":
      delete state.entities.reviewComments[action.reviewCommentId];
      return {
        ...state,
        entities: {
          ...state.entities,
          reviewComments: state.entities.reviewComments,
        },
        result: state.result.filter((id) => action.reviewCommentId !== id),
      };
    case "CHANGE_REVIEW_COMMENT_VALUE":
      return {
        ...state,
        [action.key]: action.value,
      };
    case "CREATE_REVIEW_COMMENT_SUCCESS":
      return {
        ...state,
        content: "",
        attachment: "",
        filename: "",
      };
    case "UPDATE_REVIEW_COMMENT_SUCCESS": {
      return {
        ...state,
        entities: {
          reviewComments: {
            ...state.entities.reviewComments,
            [action.reviewCommentId]: {
              ...state.entities.reviewComments[action.reviewCommentId],
              content: action.content,
            },
          },
        },
      };
    }
    case "DELETE_REVIEW_COMMENT_SUCCESS": {
      return {
        ...state,
        entities: {
          reviewComments: {
            ...state.entities.reviewComments,
            [action.reviewCommentId]: {
              ...state.entities.reviewComments[action.reviewCommentId],
              deletedAt: new Date(),
            },
          },
        },
      };
    }
    case "MARK_REVIEW_COMMENT_SUCCESS": {
      return {
        ...state,
        entities: {
          reviewComments: {
            ...state.entities.reviewComments,
            [action.reviewCommentId]: {
              ...state.entities.reviewComments[action.reviewCommentId],
              status: "marked",
              markedAt: today().toDate(),
              markedUser: action.currentUser,
            },
          },
        },
      };
    }
    case "UNMARK_REVIEW_COMMENT_SUCCESS": {
      return {
        ...state,
        entities: {
          reviewComments: {
            ...state.entities.reviewComments,
            [action.reviewCommentId]: {
              ...state.entities.reviewComments[action.reviewCommentId],
              status: "unmarked",
            },
          },
        },
      };
    }
    case "DELETE_REVIEW_REPLY_SUCCESS": {
      const replies = state.entities.reviewComments[
        action.reviewCommentId
      ].replies.filter((reply) => reply.id !== action.reviewReplyId);
      return {
        ...state,
        entities: {
          ...state.entities,
          reviewComments: {
            ...state.entities.reviewComments,
            [action.reviewCommentId]: {
              ...state.entities.reviewComments[action.reviewCommentId],
              replies,
            },
          },
        },
      };
    }
    case "SET_CHECK_FILTER": {
      return {
        ...state,
        checkFilter: action.checkFilter,
      };
    }
    case "CLEAR_CREATIVE_REVIEW_PAGE":
      return initialState;
    default:
      return state;
  }
}
