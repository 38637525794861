import { lazy } from "react";

const lazyWithRetry = (componentImport: () => Promise<any>) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed =
      window.localStorage.getItem("page-has-been-force-refreshed") || "false";
    try {
      const component = await componentImport();

      window.localStorage.setItem("page-has-been-force-refreshed", "false");

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
        });
        window.localStorage.setItem("page-has-been-force-refreshed", "true");
        return window.location.reload();
      }

      throw error;
    }
  });

export default lazyWithRetry;
