import EquipmentReservationRes from "@/types/res/EquipmentReservationRes";

export type EquipmentReservationState = {
  entities: {
    equipmentReservations: { [key: number]: EquipmentReservationRes };
  };
  result: number[];
};
const initialState = {} as EquipmentReservationState;
export default function equipmentReservationReducer(
  state = initialState,
  action: {
    type: string;
    data: EquipmentReservationState;
  },
) {
  switch (action.type) {
    case "CREATE_EQUIPMENT_RESERVATION_SUCCESS":
      return {
        entities: {
          equipmentReservations: {
            ...state.entities.equipmentReservations,
            ...action.data.entities.equipmentReservations,
          },
        },
        result: [...state.result, ...action.data.result],
      };
    case "UPDATE_EQUIPMENT_RESERVATION_SUCCESS": {
      return {
        entities: {
          equipmentReservations: {
            ...state.entities.equipmentReservations,
            ...action.data.entities.equipmentReservations,
          },
        },
        result: state.result,
      };
    }
    case "GET_EQUIPMENT_RESERVATIONS_SUCCESS":
      return action.data;
    default:
      return state;
  }
}
