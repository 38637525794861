import ProjectTagRes from "@/types/res/ProjectTagRes";

export type ProjectTagState = {
  entities: { projectTags: { [key: number]: ProjectTagRes } };
  result: number[];
};
const initialState = {} as ProjectTagState;
export default function projectTagReducer(
  state = initialState,
  action: {
    type: string;
    data: ProjectTagState;
    id: number;
  },
) {
  switch (action.type) {
    case "GET_PROJECT_TAGS_SUCCESS":
      return action.data;
    case "CREATE_PROJECT_TAG_SUCCESS": {
      return {
        entities: {
          projectTags: {
            ...state.entities.projectTags,
            ...action.data.entities.projectTags,
          },
        },
        result: [...action.data.result, ...state.result],
      };
    }
    case "UPDATE_PROJECT_TAG_SUCCESS": {
      return {
        entities: {
          projectTags: {
            ...state.entities.projectTags,
            ...action.data.entities.projectTags,
          },
        },
        result: state.result,
      };
    }
    case "DESTROY_PROJECT_TAG_SUCCESS": {
      delete state.entities.projectTags[action.id];
      return {
        ...state,
        entities: {
          projectTags: { ...state.entities.projectTags },
        },
        result: state.result.filter((id) => id !== action.id),
      };
    }
    default:
      return state;
  }
}
