export type PrefectureState = {
  prefectures: { id: id; name: string }[];
};
const initialState: PrefectureState = {
  prefectures: [],
};
export default function prefectureReducer(
  state = initialState,
  action: { type: string; prefectures: { id: id; name: string }[] },
) {
  switch (action.type) {
    case "GET_PREFECTURES_SUCCESS":
      return {
        ...state,
        prefectures: action.prefectures,
      };
    default:
      return state;
  }
}
