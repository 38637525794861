import EquipmentSetRes from "@/types/res/EquipmentSetRes";

export type EquipmentSetState = {
  entities: {
    equipmentSets: { [key: number]: EquipmentSetRes };
  };
  result: number[];
};
const initialState = {} as EquipmentSetState;
export default function equipmentSetReducer(
  state = initialState,
  action: {
    type: string;
    data: EquipmentSetState;
    id: number;
  },
) {
  switch (action.type) {
    case "GET_EQUIPMENT_SETS_SUCCESS":
      return action.data;
    case "POST_EQUIPMENT_SET_SUCCESS": {
      return {
        entities: {
          equipmentSets: {
            ...state.entities.equipmentSets,
            ...action.data.entities.equipmentSets,
          },
        },
        result: [...state.result, ...action.data.result],
      };
    }
    case "UPDATE_EQUIPMENT_SET_SUCCESS": {
      return {
        entities: {
          equipmentSets: {
            ...state.entities.equipmentSets,
            ...action.data.entities.equipmentSets,
          },
        },
        result: state.result,
      };
    }
    case "DELETE_EQUIPMENT_SET_SUCCESS": {
      delete state.entities.equipmentSets[action.id];
      return {
        entities: {
          equipmentSets: {
            ...state.entities.equipmentSets,
          },
        },
        result: state.result.filter((id) => id !== action.id),
      };
    }
    default:
      return state;
  }
}
