import {
  blueLighten1,
  gray,
  grayDarken2,
  grayLighten1,
  green,
  greenDarken1,
  greenDarken2,
  greenLighten2,
  yellowDarken2,
  yellowLighten2,
  yellowLighten3,
} from "@/shared/libs/palette";

const COLORs = {
  main: "#1B76EA",
  mainHover: "#7BABFF",
  mainTransparency: "#BAD4FF",
  mainButtonBg: "#1673E9",
  secondary: "#F3B96F",
  textXXLight: "#DDDDDD",
  textXLight: "#BBBBBB",
  textLight: "#999999",
  textRegular: "#666666",
  textDarken: "#333333",
  formBg: "#F2F2F2",
  placeholder: "#BBBBBB",
  switchOff: "#DDDDDD",
  checkboxBorder: "#707070",
  templateBorder: "#707070",
  border: "#DDDDDD",
  borderOnFocus: "#5F9AFF",
  userIconBg: "#B3B3B3",
  dashedBorder: "#666666",
  modalFooter: "#F6F8FA",
  bgColor: greenDarken2, // will remove
  bgColorHover: greenDarken1, // will remove
  activeTab: green, // will remove
  pageTitle: grayDarken2, // will remove
  iconBgColor: greenLighten2, // will remove
  searchBgColor: greenLighten2, // will remove
  textLink: "#5F9AFF",
  checked: "#84D881",
  success: "#84D881",
  progress: "#F5DA69",
  progressGood: "#84D881",
  progressBad: "#FC8B35",
  todayDateItem: "#F44545",
  currentTimeLine: "#F44545",
  warning: "#FC8B35", // ユーザーが随意的に操作できるものをwarningとする
  warningHover: "#FF9B45",
  warningBg: "#F6F1E7",
  attention: "#F44545",
  error: "#F44545",
  danger: "#F44545",
  dangerHover: "#EF7A7A",
  disableButton: gray,
  activeState: "#55c500", // will remove
  popupHeaderItemBgColor: yellowLighten2, // will remove
  notificationUnreadMarkColor: "#F44545",
  notificationUnreadBgColor: yellowLighten3, // will remove
  unreadCount: "#F44545",
  comingSoon: greenDarken2, // will remove
  iconColor: "#999",
  popupHover: "#f9f9f9",
  popupSelectedHover: "#f1f1f1",
  popupChecked: "#eaeaea",
  loading: "#75c0f9",
  white: "#ffffff",
  readOnlySchedule: "#999999",
  mention: "#84D881",
  /* レビュー画面関連 */
  reviewText: gray,
  reviewBase: "#16151d",
  reviewHeader: "#22222a",
  repositoryPopupBgColor: "#2d303d",
  repositoryPopupBgColorHover: "#444454",
  repositoryPopupLinkText: grayLighten1,
  commentBase: "#22222a",
  commentBgColor: "#2c2c36",
  commentBgColorActive: "#13151a",
  commentBgColorHover: "#444454",
  timeBgColorActive: "#1c1f26",
  progressBar: gray,
  progressStatus: yellowDarken2,
  reviewPointBgColor: "#2d303d",
  toolBoxBgColor: "#2d303d",
  playbackSpeedMark: "#F44545",
  userIconBgColor: "#dbdbdb",
  rangeSelector: "yellow",
  drawingModeIcon: "#0f0f14",
  /* Drawing関連 */
  drawingRed: "#e74a3c",
  drawingBlue: "#34a3db",
  drawingBlack: "#212121",
  drawingWhite: "#efefef",
  /* CreativeFile.status */
  waiting: yellowDarken2,
  ongoing: blueLighten1,
  completed: "#55c500",
  /* Renewal Design Color by Kayo */
  // TODO: 全ページデザインリニューアル完了後
  //       1. 使わなくなったカラー定義を削除
  //       2. prefixのkayoを削除
  kayoTextDarken: "#000000",
  kayoTextLightDarken: "#191919",
  kayoTextXLight: "#8b8b8b",
  kayoTextXXXLight: "#dbdbdb",
  kayoXLightGrayBg: "#f8f8f8",
  kayoLightGrayBg: "#f2f2f2",
  kayoGrayBg: "#e8e8e8",
  kayoDarkGrayBg: "#dedede",
  kayoGrayBorder: "#e2e2e2",
  kayoTagBlack: "#5a5a5a",
  tableHeader: "#161616",
  roleBgColor: "#FFF6EC",
  roleTextColor: "#EA5425",
  channelBgColor: "#FFF0F7",
  channelTextColor: "#E21C90",
  tableHeaderLight: "#424242",
  /* UX再設計後カラーコード */
  black: "#000000",
  xLightGray: "#e0e0e0",
  youtubeRed: "#ff0000",
  calendarActiveOrange: "#E17269",
} as const;
export default COLORs;
