import HolidaySettingRes from "@/types/res/HolidaySettingRes";
import TeamHolidayRes from "@/types/res/TeamHolidayRes";

export type HolidaySettingState = {
  entities: {
    holidaySettings: { [key: number]: HolidaySettingRes };
  };
  result: number[];
};
const initialState = {} as HolidaySettingState;
export default function holidaySettingReducer(
  state = initialState,
  action: {
    type: string;
    data: HolidaySettingState;
    teamHoliday: TeamHolidayRes;
  },
) {
  switch (action.type) {
    case "GET_HOLIDAY_SETTING_SUCCESS":
      return action.data;
    case "UPDATE_HOLIDAY_SETTING_SUCCESS":
      return {
        entities: {
          holidaySettings: {
            ...state.entities.holidaySettings,
            ...action.data.entities.holidaySettings,
          },
        },
        result: state.result,
      };
    case "POST_TEAM_HOLIDAY_SUCCESS":
      return {
        entities: {
          holidaySettings: {
            ...state.entities.holidaySettings,
            ...action.data.entities.holidaySettings,
          },
        },
        result: state.result,
      };
    case "UPDATE_TEAM_HOLIDAY_SUCCESS":
      return {
        entities: {
          holidaySettings: {
            ...state.entities.holidaySettings,
            ...action.data.entities.holidaySettings,
          },
        },
        result: state.result,
      };
    case "DELETE_TEAM_HOLIDAY_SUCCESS": {
      return {
        entities: {
          holidaySettings: {
            [action.teamHoliday.holidaySettingId]: {
              ...state.entities.holidaySettings[
                action.teamHoliday.holidaySettingId
              ],
              teamHolidays: state.entities.holidaySettings[
                action.teamHoliday.holidaySettingId
              ].teamHolidays.filter(
                (teamHoliday) => teamHoliday.id !== action.teamHoliday.id,
              ),
            },
          },
        },
        result: state.result,
      };
    }
    default:
      return state;
  }
}
