import ReviewProjectRes from "@/types/res/ReviewProjectRes";

type ReviewSchemaState = {
  entities: {
    reviews: { [key: number]: ReviewProjectRes };
  };
  result: number[];
};
export type ReviewState = {
  myReviews: number[];
  myReviewsCount: number;
  allReviews: number[];
  allReviewsCount: number;
  recentlyCreatedReviewProjects: ReviewProjectRes[];
} & ReviewSchemaState;
const initialState = {} as ReviewState;

export default function reviewReducer(
  state = initialState,
  action: {
    type: string;
    data: ReviewSchemaState;
    myReviews: number[];
    myReviewsCount: number;
    allReviews: number[];
    allReviewsCount: number;
    recentlyCreatedReviewProjects: ReviewProjectRes;
  },
) {
  switch (action.type) {
    case "GET_MY_REVIEWS_SUCCESS":
      return {
        ...state,
        entities: {
          reviews: {
            ...state.entities?.reviews,
            ...action.data.entities?.reviews,
          },
        },
        result: [],
        myReviews: action.data.result,
      };
    case "GET_MY_REVIEWS_COUNT_SUCCESS":
      return {
        ...state,
        myReviewsCount: action.myReviewsCount,
      };
    case "GET_ALL_REVIEWS_SUCCESS":
      return {
        ...state,
        entities: {
          reviews: {
            ...state.entities?.reviews,
            ...action.data.entities?.reviews,
          },
        },
        result: [],
        allReviews: action.data.result,
      };
    case "GET_ALL_REVIEWS_COUNT_SUCCESS":
      return {
        ...state,
        allReviewsCount: action.allReviewsCount,
      };
    case "GET_RECENTLY_CREATED_REVIEW_SUCCESS":
      return {
        ...state,
        recentlyCreatedReviewProjects: [
          action.recentlyCreatedReviewProjects,
          ...state.recentlyCreatedReviewProjects,
        ],
      };
    case "CLEAR_RECENTLY_CREATED_REVIEW":
      return {
        ...state,
        recentlyCreatedReviewProjects: [],
      };
    default:
      return state;
  }
}
