import ProcessGroupRes from "@/types/res/ProcessGroupRes";

export type ProcessGroupState = {
  entities: {
    processGroups: { [key: number]: ProcessGroupRes };
  };
  result: number[];
};
const initialState = {} as ProcessGroupState;
export default function processGroupReducer(
  state = initialState,
  action: {
    type: string;
    data: ProcessGroupState;
    id: number;
  },
) {
  switch (action.type) {
    case "GET_PROCESS_GROUPS_SUCCESS":
      return action.data;
    default:
      return state;
  }
}
