import EquipmentRes from "@/types/res/EquipmentRes";

export type EquipmentSelectionState = {
  entities: {
    equipments: { [key: number]: EquipmentRes };
  };
  result: number[];
};
const initialState = {} as EquipmentSelectionState;
export default function equipmentSelectionReducer(
  state = initialState,
  action: {
    type: string;
    data: EquipmentSelectionState;
  },
) {
  switch (action.type) {
    case "GET_EQUIPMENT_SELECTIONS_SUCCESS":
      return action.data;
    case "CLEAR_EQUIPMENT_SELECTIONS": {
      return initialState;
    }
    default:
      return state;
  }
}
