import ChannelRes from "@/types/res/ChannelRes";

type ChannelDataState = {
  entities: {
    channels: { [key: number]: ChannelRes };
  };
  result: number[];
};
export type ChannelState = {
  isSetChannelIds: boolean;
  channel: ChannelRes;
} & ChannelDataState;
const initialState = {
  entities: {
    channels: {},
  },
  result: [],
  isSetChannelIds: false,
  channel: {} as ChannelRes,
} as ChannelState;
export default function channelReducer(
  state = initialState,
  action: {
    type: string;
    data: ChannelDataState;
    channel: ChannelRes;
    id: number;
  },
) {
  switch (action.type) {
    case "GET_CHANNELS_SUCCESS": {
      return {
        ...state,
        isSetChannelIds: true,
        ...action.data,
      };
    }
    case "GET_CHANNEL_SUCCESS": {
      return {
        ...state,
        channel: { ...action.channel },
      };
    }
    default:
      return state;
  }
}
