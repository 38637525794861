import CreativeFileRes from "@/types/res/CreativeFileRes";

export type CreativeFileTypeState = {
  entities: {
    creativeFileTypes: { [key: number]: CreativeFileRes };
  };
  result: number[];
  creativeFileId: number;
};

const initialState = {} as CreativeFileTypeState;

export default function creativeFileTypeReducer(
  state = initialState,
  action: {
    type: string;
    id: number;
    data: CreativeFileTypeState;
  },
) {
  switch (action.type) {
    case "GET_CREATIVE_FILE_SUCCESS":
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.data.entities,
        },
        creativeFileId: action.id,
      };
    case "GET_CREATIVE_FILE_START":
      return {
        ...state,
        creativeFileId: undefined,
      };
    default:
      return state;
  }
}
