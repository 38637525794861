import FilePropertyRes from "@/types/res/FilePropertyRes";

export type FilePropertyState = {
  entities: {
    fileProperties: { [key: number]: FilePropertyRes };
  };
  result: number[];
};
const initialState = {} as FilePropertyState;
export default function filePropertyReducer(
  state = initialState,
  action: {
    type: string;
    data: FilePropertyState;
    id: number;
  },
) {
  switch (action.type) {
    case "GET_FILE_PROPERTIES_SUCCESS":
      return action.data;
    default:
      return state;
  }
}
