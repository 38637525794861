// class-transformer's requirement
import "reflect-metadata";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { CookiesProvider } from "react-cookie";
import App from "./App";
import ErrorView from "./ErrorView";
import "url-search-params-polyfill";
Bugsnag.start({
  apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
  appVersion: import.meta.env.VITE_APPLICATION_ENV,
  enabledReleaseStages: ["production", "staging"],
  releaseStage: import.meta.env.VITE_APPLICATION_ENV,
  plugins: [new BugsnagPluginReact()],
});
if (import.meta.env.DEV) {
  window.onerror = (message, source, lineno, colno, error) => {
    console.error("Global error:", { message, source, lineno, colno, error });
  };
}
const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <ErrorBoundary FallbackComponent={ErrorView}>
    <Auth0Provider
      domain={import.meta.env.VITE_AUTH0_DOMAIN}
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: import.meta.env.VITE_AUTH0_CALLBACK_URL,
      }}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      <CookiesProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </CookiesProvider>
    </Auth0Provider>
  </ErrorBoundary>,
);
