import ProjectRes from "@/types/res/ProjectRes";
import UserRes from "@/types/res/UserRes";
type AssetSearchDataState = {
  entities: {
    projects: { [key: number]: ProjectRes };
    users: { [key: number]: UserRes };
  };
  result: number[];
};
export type AssetSearchState = {
  keyword: string;
  projectStatus: string;
} & AssetSearchDataState;

type Action = {
  type: string;
  data: AssetSearchDataState;
  keyword: string;
  projectStatus: string;
};
const initialState = { projectStatus: "ongoing" } as AssetSearchState;
export const assetSearchReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case "SEARCH_ASSET_PROJECTS_SUCCESS":
      return { ...state, ...action.data };
    case "CHANGE_ASSET_SEARCH_KEYWORD":
      return {
        ...state,
        keyword: action.keyword,
      };
    case "CHANGE_ASSET_SEARCH_PROJECT_STATUS":
      return {
        ...state,
        projectStatus: action.projectStatus,
      };
    default:
      return state;
  }
};
