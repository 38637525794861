import ProjectRes from "@/types/res/ProjectRes";

type ArchiveRecommendProjectSchemaState = {
  entities: {
    projects: { [key: number]: ProjectRes };
  };
  result: number[];
};
export type ArchiveRecommendProjectState = {
  totalCount: number;
} & ArchiveRecommendProjectSchemaState;

const initialState = {} as ArchiveRecommendProjectState;
export default function archiveRecommendProjectReducer(
  state = initialState,
  action: {
    type: string;
    data: ArchiveRecommendProjectState;
    totalCount: number;
    ids: number[];
  },
) {
  switch (action.type) {
    case "GET_ARCHIVE_RECOMMEND_PROJECTS_SUCCESS":
      return {
        ...state,
        entities: action.data.entities,
        result: action.data.result,
      };
    case "GET_ARCHIVE_RECOMMEND_PROJECTS_COUNT_SUCCESS":
      return {
        ...state,
        totalCount: action.totalCount,
      };
    case "REMOVE_ARCHIVE_RECOMMEND_PROJECTS_SUCCESS": {
      if (!state.entities) return state;
      action.ids.forEach((id) => {
        delete state.entities.projects[id];
      });
      return {
        entities: {
          projects: {
            ...state.entities.projects,
          },
        },
        result: state.result.filter((id) => !action.ids.includes(id)),
        totalCount: state.totalCount - 1,
      };
    }
    default:
      return state;
  }
}
