import ProjectRes from "@/types/res/ProjectRes";

export type MyProjectState = {
  entities: {
    projects: { [key: number]: ProjectRes };
  };
  result: number[];
};

const initialState = {} as MyProjectState;
export default function myProjectReducer(
  state = initialState,
  action: {
    type: string;
    data: MyProjectState;
  },
) {
  switch (action.type) {
    case "GET_MY_PROJECTS_SUCCESS":
      return {
        entities: {
          projects: {
            ...action.data.entities.projects,
            ...state.entities?.projects,
          },
        },
        result: Array.from(
          new Set([...action.data.result, ...(state.result || [])]),
        ),
      };
    default:
      return state;
  }
}
