import IpWhitelistSettingRes from "@/types/res/IpWhitelistSettingRes";

export type IpWhitelistSettingState = {
  ipWhitelistSetting: IpWhitelistSettingRes;
};
const initialState: IpWhitelistSettingState = {
  ipWhitelistSetting: {} as IpWhitelistSettingRes,
};
export default function ipWhitelistSettingReducer(
  state = initialState,
  action: {
    type: string;
    ipWhitelistSetting: IpWhitelistSettingRes;
  },
) {
  switch (action.type) {
    case "GET_IP_WHITELIST_SETTING_SUCCESS": {
      return {
        ipWhitelistSetting: action.ipWhitelistSetting,
      };
    }
    default:
      return state;
  }
}
