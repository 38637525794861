import DictionaryRes from "@/types/res/DictionaryRes";

export type DictionaryState = {
  dictionary: DictionaryRes;
};
const initialState = {
  dictionary: {} as DictionaryRes,
} as DictionaryState;
export default function dictionaryReducer(
  state = initialState,
  action: {
    type: string;
    result: DictionaryRes;
  },
) {
  switch (action.type) {
    case "GET_DICTIONARY_SUCCESS":
      return { dictionary: action.result };
    case "UPDATE_DICTIONARY_SUCCESS":
      return { dictionary: action.result };
    default:
      return state;
  }
}
