import EquipmentCategoryRes from "@/types/res/EquipmentCategoryRes";

export type EquipmentCategoryState = {
  entities: {
    equipmentCategories: { [key: number]: EquipmentCategoryRes };
  };
  result: number[];
};
const initialState = {} as EquipmentCategoryState;
export default function equipmentCategoryReducer(
  state = initialState,
  action: {
    type: string;
    data: EquipmentCategoryState;
  },
) {
  switch (action.type) {
    case "GET_EQUIPMENT_CATEGORIES_SUCCESS":
      return action.data;
    default:
      return state;
  }
}
