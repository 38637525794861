import ProjectAssetCompositionRes from "@/types/res/ProjectAssetCompositionRes";

export type AssetCompositionState = {
  entities: {
    projectAssetCompositions: { [key: number]: ProjectAssetCompositionRes };
  };
  result: number[];
};

type Action = {
  type: string;
  data: AssetCompositionState;
};
const initialState = {} as AssetCompositionState;
export const assetCompositionReducer = (
  state = initialState,
  action: Action,
) => {
  switch (action.type) {
    case "GET_PROJECT_ASSET_COMPOSITIONS_SUCCESS":
      return action.data;
    default:
      return state;
  }
};
