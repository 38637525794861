type State = {
  contactType: "contact" | "download" | "demo";
};
const initialState: State = {
  contactType: null,
};
export default function contactModalReducer(
  state = initialState,
  action: { type: string; contactType: "contact" | "download" | "demo" },
) {
  switch (action.type) {
    case "OPEN_CONTACT_MODAL":
      return {
        ...state,
        contactType: action.contactType,
      };
    case "CLOSE_CONTACT_MODAL":
      return {
        ...state,
        contactType: null,
      };
    default:
      return state;
  }
}
