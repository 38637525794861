type TeamState = {
  teams: any[];
  thumbnail: string;
  name: string;
  postalCode: string;
  prefectureId: id;
  firstAddress: string;
  secondAddress: string;
  teamSize: string;
  creativeTypes: string[];
  needFunctions: string[];
};
const initialState: TeamState = {
  teams: [],
  thumbnail: "",
  name: "",
  postalCode: "",
  prefectureId: null,
  firstAddress: "",
  secondAddress: "",
  teamSize: "",
  creativeTypes: [],
  needFunctions: [],
};
export default function teamReducer(
  state = initialState,
  action: { type: string; teams: any[]; key: string; value: string },
) {
  switch (action.type) {
    case "GET_TEAMS_SUCCESS":
      return {
        ...state,
        teams: action.teams,
      };
    case "CHANGE_TEAM_VALUE":
      return {
        ...state,
        [action.key]: action.value,
      };
    case "CREATE_TEAM_SUCCESS":
      return initialState;
    default:
      return state;
  }
}
