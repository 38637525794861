import UserRes from "@/types/res/UserRes";

export type UserState = {
  isJoining: boolean;
  user: UserRes;
};
const initialState: UserState = {
  isJoining: false,
  user: null,
};

type Action =
  | "JOIN_BY_INVITATION"
  | "JOIN_BY_INVITATION_SUCCESS"
  | "JOIN_BY_INVITATION_FAILURE"
  | "GET_USER_PROFILE_AND_ABILITIES_SUCCESS";

export default function userReducer(
  state = initialState,
  action: {
    type: Action;
    user: UserRes;
  },
) {
  switch (action.type) {
    case "JOIN_BY_INVITATION":
      return {
        ...state,
        isJoining: true,
      };
    case "JOIN_BY_INVITATION_SUCCESS":
      return {
        ...state,
        isJoining: false,
      };
    case "JOIN_BY_INVITATION_FAILURE":
      return {
        ...state,
        isJoining: false,
      };
    case "GET_USER_PROFILE_AND_ABILITIES_SUCCESS":
      return {
        ...state,
        user: action.user,
      };
    default:
      return state;
  }
}
