export type ModalState = {
  planEditModal: { isDisplay: boolean; projectId: number };
  createChatRoomModal: { isDisplay: boolean };
};
const initialState: ModalState = {
  planEditModal: { isDisplay: false, projectId: null },
  createChatRoomModal: { isDisplay: false },
};
export default function modalReducer(
  state = initialState,
  action: {
    type: string;
    planEditModal: { isDisplay: boolean; projectId: number };
  },
) {
  switch (action.type) {
    case "CHANGE_PLAN_EDIT_MODAL":
      return {
        ...state,
        planEditModal: { ...action.planEditModal },
      };
    case "OPEN_CREATE_CHAT_ROOM_MODAL":
      return {
        ...state,
        createChatRoomModal: {
          isDisplay: true,
        },
      };
    case "RESET_CREATE_CHAT_ROOM_MODAL":
      return {
        ...state,
        createChatRoomModal: {
          isDisplay: false,
        },
      };
    default:
      return state;
  }
}
