import OauthTokenRes from "@/types/res/OauthTokenRes";

export type OauthTokenState = {
  entities: {
    oauthTokens: { [key: number]: OauthTokenRes };
  };
  result: number[];
};
const initialState: OauthTokenState = {
  entities: { oauthTokens: {} },
  result: [],
};
export default function oauthTokenReducer(
  state = initialState,
  action: {
    type: string;
    data: OauthTokenState;
    id: number;
  },
) {
  switch (action.type) {
    case "GET_OAUTH_TOKENS_SUCCESS":
      return {
        ...state,
        entities: {
          oauthTokens: {
            ...state.entities.oauthTokens,
            ...action.data.entities.oauthTokens,
          },
        },
        result: action.data.result,
      };
    case "CREATE_OAUTH_TOKEN_SUCCESS":
      return {
        ...state,
        entities: {
          oauthTokens: {
            ...state.entities.oauthTokens,
            ...action.data.entities.oauthTokens,
          },
        },
        result: [...action.data.result, ...state.result],
      };
    case "DELETE_OAUTH_TOKEN_SUCCESS": {
      delete state.entities.oauthTokens[action.id];
      return {
        entities: {
          oauthTokens: { ...state.entities.oauthTokens },
        },
        result: state.result.filter((id) => id !== action.id),
      };
    }
    default:
      return state;
  }
}
