import OutsourceProfessionRes from "@/types/res/OutsourceProfessionRes";

export type OutsourceProfessionState = {
  entities: {
    outsourceProfessions: { [key: number]: OutsourceProfessionRes };
  };
  result: number[];
};
const initialState = {} as OutsourceProfessionState;
export default function outsourceProfessionReducer(
  state = initialState,
  action: {
    type: string;
    data: OutsourceProfessionState;
  },
) {
  switch (action.type) {
    case "GET_OUTSOURCE_PROFESSIONS_SUCCESS":
      return action.data;
    default:
      return state;
  }
}
