import ContractRes from "@/types/res/ContractRes";
import NotificationSettingRes from "@/types/res/NotificationSettingRes";
import PlanConditionRes from "@/types/res/PlanConditionRes";
import SuperUserRes from "@/types/res/SuperUserRes";
import TeamSettingRes from "@/types/res/TeamSettingRes";
import UsecaseAbilityRes from "@/types/res/UsecaseAbilityRes";
import UserRes from "@/types/res/UserRes";

export type CurrentState = {
  signinError: boolean;
  superUser: SuperUserRes;
  users: UserRes[];
  teamUsers: UserRes[];
  user: UserRes;
  guest: {
    identifier: string;
  };
  notificationSetting: NotificationSettingRes;
  contract: ContractRes;
  planCondition: PlanConditionRes;
  teamSetting: TeamSettingRes;
  projectId: number;
  usecaseAbilities: UsecaseAbilityRes[];
  creativeAbilityUsers: UserRes[];
  isFetchingSuperUser: boolean;
};
const initialState: CurrentState = {
  signinError: false,
  superUser: {} as SuperUserRes,
  users: [],
  teamUsers: [],
  user: {} as UserRes,
  guest: {
    identifier: "",
  },
  notificationSetting: {} as NotificationSettingRes,
  contract: {
    id: null,
    plan: "",
    accountCount: 0,
    projectCount: 0,
    fileSize: 0,
    monthlyUploadFileSize: 0,
    billingDestination: "",
    billingAddress: "",
    teamId: null,
    beginAt: null,
    endAt: null,
    storageUpperBound: 0,
    gigabytePerPayUnit: 0,
    priceJpyPerPayUnit: 0,
    monthlyUploadUpperBound: 0,
    willEndPlan: false,
    payedStorageCapacityGiga: 0,
    displayPlanTitle: "",
    createdAt: null,
    updatedAt: null,
    createdById: null,
    updatedById: null,
  },
  planCondition: {} as PlanConditionRes,
  teamSetting: {} as TeamSettingRes,
  projectId: null,
  usecaseAbilities: [],
  creativeAbilityUsers: [],
  isFetchingSuperUser: false,
};
export default function currentReducer(
  state = initialState,
  action: {
    type: string;
    superUser: SuperUserRes;
    users: UserRes[];
    teamUsers: UserRes[];
    user: UserRes;
    guest: {
      identifier: string;
    };
    notificationSetting: NotificationSettingRes;
    contract: ContractRes;
    planCondition: PlanConditionRes;
    teamSetting: TeamSettingRes;
    projectId: number;
    usecaseAbilities: UsecaseAbilityRes[];
    creativeAbilityUsers: UserRes[];
    projectIds: number[];
    userIds: number[];
    includeChannels: boolean;
  },
) {
  switch (action.type) {
    case "START_GET_CURRENT_SUPER_USER":
      return {
        ...state,
        isFetchingSuperUser: true,
      };
    case "GET_CURRENT_SUPER_USER_SUCCESS":
      return {
        ...state,
        superUser: action.superUser,
        isFetchingSuperUser: false,
      };
    case "GET_CURRENT_SUPER_USER_FAILURE":
      return {
        ...state,
        isFetchingSuperUser: false,
      };
    case "GET_CURRENT_USERS_SUCCESS":
      return {
        ...state,
        users: action.users,
      };
    case "GET_CURRENT_TEAM_USERS_SUCCESS":
      return {
        ...state,
        teamUsers: action.teamUsers,
      };
    case "GET_CURRENT_USER_SUCCESS":
      return {
        ...state,
        user: action.user,
        projectId: null,
        usecaseAbilities: [],
        creativeAbilityUsers: [],
      };
    case "GET_CURRENT_USER_FAILURE":
      return {
        ...state,
        signinError: true,
      };
    case "GET_CURRENT_USERS_FAILURE":
      return {
        ...state,
        signinError: true,
      };
    case "UPDATE_CURRENT_USER_SUCCESS":
      return {
        ...state,
        user: action.user,
      };
    case "GET_NOTIFICATION_SETTING_SUCCESS":
      return {
        ...state,
        notificationSetting: action.notificationSetting,
      };
    case "UPDATE_NOTIFICATION_SETTING_SUCCESS":
      return {
        ...state,
        notificationSetting: action.notificationSetting,
      };
    case "GET_CONTRACT_SUCCESS":
      return {
        ...state,
        contract: action.contract,
      };
    case "UPDATE_CONTRACT_SUCCESS":
      return {
        ...state,
        contract: action.contract,
      };
    case "GET_PLAN_CONDITION_SUCCESS":
      return {
        ...state,
        planCondition: action.planCondition,
      };
    case "GET_TEAM_SETTING_SUCCESS":
      return {
        ...state,
        teamSetting: action.teamSetting,
      };
    case "UPDATE_TEAM_SETTING_SUCCESS":
      return {
        ...state,
        teamSetting: action.teamSetting,
      };
    case "GET_CREATIVE_ABILITY_USERS_SUCCESS":
      return {
        ...state,
        creativeAbilityUsers: action.creativeAbilityUsers,
      };
    case "SET_CURRENT_PROJECT":
      return {
        ...state,
        projectId: action.projectId,
      };
    case "COMPLETE_NORMAL_TUTORIAL": {
      const superUser = state.superUser;
      return {
        ...state,
        superUser: {
          ...superUser,
          tutorialStatus:
            superUser.tutorialStatus === "review_completed"
              ? "completed"
              : "normal_completed",
        },
      };
    }
    case "COMPLETE_REVIEW_TUTORIAL": {
      const superUser = state.superUser;
      return {
        ...state,
        superUser: {
          ...superUser,
          tutorialStatus:
            superUser.tutorialStatus === "normal_completed"
              ? "completed"
              : "review_completed",
        },
      };
    }
    case "GET_CURRENT_USECASE_ABILITIES_SUCCESS": {
      return {
        ...state,
        usecaseAbilities: action.usecaseAbilities,
      };
    }
    default:
      return state;
  }
}
