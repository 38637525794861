import ShareViewSettingRes from "@/types/res/ShareViewSettingRes";

export type ShareViewSettingState = {
  shareViewSetting: ShareViewSettingRes;
};
const initialState = {} as ShareViewSettingState;
export default function shareViewSettingReducer(
  state = initialState,
  action: {
    type: string;
    data: ShareViewSettingState;
  },
) {
  switch (action.type) {
    case "GET_SHARE_VIEW_SETTING_SUCCESS":
      return action.data;
    case "UPDATE_SHARE_VIEW_SETTING_SUCCESS":
      return action.data;
    default:
      return state;
  }
}
