import UserNotificationSettingContentReq from "@/types/req/UserNotificationSettingContentReq";
import UserNotificationSettingRes from "@/types/res/UserNotificationSettingRes";

export type UserNotificationSettingState = {
  userNotificationSetting: UserNotificationSettingRes;
};
const initialState = {
  userNotificationSetting: {} as UserNotificationSettingRes,
} as UserNotificationSettingState;
export default function userNotificationSettingReducer(
  state = initialState,
  action: {
    type: string;
    result: UserNotificationSettingRes;
    object: UserNotificationSettingContentReq;
  },
) {
  switch (action.type) {
    case "GET_USER_NOTIFICATION_SETTING_SUCCESS":
      return { userNotificationSetting: action.result };
    case "UPDATE_USER_NOTIFICATION_SETTING_SUCCESS":
      return { userNotificationSetting: action.result };
    case "OPTIMISTIC_UPDATE_USER_NOTIFICATION_SETTING":
      return {
        userNotificationSetting: {
          ...state.userNotificationSetting,
          content: {
            ...action.object,
          },
        },
      };
    default:
      return state;
  }
}
