import dayjs from "dayjs";

export type GlobalAppState = {
  isSetChannelIds: boolean;
  channelIds: number[];
  targetMonth: string;
  targetProcess: "delivery" | "post";
};
const initialState: GlobalAppState = {
  isSetChannelIds: false,
  channelIds: [],
  targetMonth: dayjs().startOf("month").format("YYYY-MM-DD"),
  targetProcess: "post",
};
export default function globalAppReducer(
  state = initialState,
  action: {
    type: string;
    channelIds: number[];
    targetMonth: string;
    targetProcess: "delivery" | "post";
  },
) {
  switch (action.type) {
    case "CHANGE_GLOBAL_APP_USER_GROUP_IDS":
      return {
        ...state,
        isSetChannelIds: true,
        channelIds: action.channelIds,
      };
    case "CHANGE_GLOBAL_APP_TARGET_MONTH":
      return {
        ...state,
        targetMonth: action.targetMonth,
      };
    case "CHANGE_GLOBAL_APP_TARGET_PROCESS":
      return {
        ...state,
        targetProcess: action.targetProcess,
      };
    default:
      return state;
  }
}
