type SuperUserState = {
  superUsers: any[];
  name: string;
  uid: id;
  email: string;
  companyName: string;
  department: string;
  telephoneNumber: string;
  slug: string;
  thumbnail: string;
};
const initialState: SuperUserState = {
  superUsers: [],
  name: "",
  uid: "",
  email: "",
  companyName: "",
  department: "",
  telephoneNumber: "",
  slug: "",
  thumbnail: "",
};
export default function superUserReducer(
  state = initialState,
  action: { type: string; key: string; value: string },
) {
  switch (action.type) {
    case "CHANGE_SUPER_USER_VALUE":
      return {
        ...state,
        [action.key]: action.value,
      };
    case "CREATE_SUPER_USER_SUCCESS":
      return initialState;
    case "UPDATE_SUPER_USER_SUCCESS":
      return initialState;
    default:
      return state;
  }
}
