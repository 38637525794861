import SlackChannelRes from "@/types/res/SlackChannelRes";
type SlackChannelDataState = {
  entities: {
    slackChannels: { [key: number]: SlackChannelRes };
  };
  result: number[];
};

export type SlackChannelState = {
  [oauthTokenId: number]: {
    paginationKey: string;
  } & SlackChannelDataState;
};
const initialState = {} as SlackChannelState;
export default function slackChannelReducer(
  state = initialState,
  action: {
    type: string;
    data: SlackChannelDataState;
    oauthTokenId: number;
    paginationKey: string;
  },
) {
  switch (action.type) {
    case "GET_SLACK_CHANNEL_LIST_SUCCESS":
      return {
        ...state,
        [action.oauthTokenId]: {
          entities: {
            slackChannels: {
              ...state?.[action.oauthTokenId]?.entities?.slackChannels,
              ...action.data.entities.slackChannels,
            },
          },
          result: [
            ...new Set([
              ...(state?.[action.oauthTokenId]?.result || []),
              ...action.data.result,
            ]),
          ],
          paginationKey: action.paginationKey,
        },
      };
    case "CLEAR_SLACK_CHANNEL_LIST": {
      return {
        ...state,
        [action.oauthTokenId]: {
          entities: {
            slackChannels: undefined,
          },
          result: undefined,
          paginationKey: undefined,
        },
      };
    }
    default:
      return state;
  }
}
