import ProjectCategoryRes from "@/types/res/ProjectCategoryRes";

export type ProjectCategoryState = {
  entities: {
    projectCategories: { [key: number]: ProjectCategoryRes };
  };
  result: number[];
};
const initialState = {} as ProjectCategoryState;
export default function projectCategoryReducer(
  state = initialState,
  action: {
    type: string;
    data: ProjectCategoryState;
    id: number;
  },
) {
  switch (action.type) {
    case "GET_PROJECT_CATEGORIES_SUCCESS":
      return action.data;
    case "CREATE_PROJECT_CATEGORY_SUCCESS": {
      return {
        entities: {
          projectCategories: {
            ...state.entities.projectCategories,
            ...action.data.entities.projectCategories,
          },
        },
        result: [...action.data.result, ...state.result],
      };
    }
    case "UPDATE_PROJECT_CATEGORY_SUCCESS": {
      return {
        entities: {
          projectCategories: {
            ...state.entities.projectCategories,
            ...action.data.entities.projectCategories,
          },
        },
        result: state.result,
      };
    }
    case "DESTROY_PROJECT_CATEGORY_SUCCESS": {
      delete state.entities.projectCategories[action.id];
      return {
        ...state,
        entities: {
          projectCategories: { ...state.entities.projectCategories },
        },
        result: state.result.filter((id) => id !== action.id),
      };
    }
    default:
      return state;
  }
}
