import UserSummaryRes from "@/types/res/UserSummaryRes";

export type UserSummaryState = {
  entities: {
    userSummaries: {
      [key: number]: UserSummaryRes;
    };
  };
  result: number[];
};
const initialState = {} as UserSummaryState;
export default function userSummaryReducer(
  state = initialState,
  action: {
    type: string;
    data: UserSummaryState;
  },
) {
  switch (action.type) {
    case "SEARCH_USER_SUMMARY_SUCCESS": {
      return action.data;
    }
    default:
      return state;
  }
}
