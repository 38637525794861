import ChatCommentFileRes from "@/types/res/ChatCommentFileRes";

export type ChatCommentFileState = {
  entities: {
    chatCommentFiles: { [key: number]: ChatCommentFileRes };
  };
  result: number[];
};
const initialState = {} as ChatCommentFileState;
export default function chatCommentFileReducer(
  state = initialState,
  action: {
    type: string;
    data: ChatCommentFileState;
  },
) {
  switch (action.type) {
    case "GET_CHAT_COMMENT_FILES_SUCCESS":
      return action.data;
    case "SWITCH_CHAT_ROOM": {
      return initialState;
    }
    default:
      return state;
  }
}
