import ChatCommentRes from "@/types/res/ChatCommentRes";

export type ChatCommentState = {
  entities: {
    chatComments: { [key: number]: ChatCommentRes };
  };
  result: number[];
};
const initialState = {} as ChatCommentState;
export default function chatCommentReducer(
  state = initialState,
  action: {
    type: string;
    data: ChatCommentState;
    id: number;
    chatComment: ChatCommentRes;
  },
) {
  switch (action.type) {
    case "GET_CHAT_COMMENTS_SUCCESS":
      return action.data;
    case "GET_SELECTED_CHAT_COMMENTS_SUCCESS":
      return action.data;
    case "LOAD_MORE_CHAT_COMMENTS_SUCCESS":
      return {
        ...state,
        entities: {
          chatComments: {
            ...state.entities.chatComments,
            ...action.data.entities.chatComments,
          },
        },
        result: [...state.result, ...action.data.result],
      };
    case "CREATE_CHAT_COMMENT_SUCCESS": {
      const chatComment = action.chatComment;
      return {
        ...state,
        entities: {
          chatComments: {
            ...state.entities.chatComments,
            [chatComment.id]: chatComment,
          },
        },
        result: [...new Set([chatComment.id, ...state.result])],
      };
    }
    case "UPDATE_CHAT_COMMENT_SUCCESS": {
      const chatComment = action.chatComment;
      return {
        ...state,
        entities: {
          chatComments: {
            ...state.entities.chatComments,
            [chatComment.id]: action.chatComment,
          },
        },
      };
    }
    case "DESTROY_CHAT_COMMENT_SUCCESS": {
      delete state.entities.chatComments[action.id];
      return {
        ...state,
        entities: {
          chatComments: { ...state.entities.chatComments },
        },
        result: state.result.filter((id) => id !== action.id),
      };
    }
    case "CREATE_CHAT_REPLY_SUCCESS": {
      const chatComment = action.chatComment;
      return {
        ...state,
        entities: {
          chatComments: {
            ...state.entities.chatComments,
            [chatComment.id]: chatComment,
          },
        },
      };
    }
    case "UPDATE_CHAT_REPLY_SUCCESS": {
      const chatComment = action.chatComment;
      return {
        ...state,
        entities: {
          chatComments: {
            ...state.entities.chatComments,
            [chatComment.id]: chatComment,
          },
        },
      };
    }
    case "DESTROY_CHAT_REPLY_SUCCESS": {
      const chatComment = Object.values(state.entities.chatComments).find(
        (comment) => {
          return comment.chatReplies.some((reply) => reply.id === action.id);
        },
      );
      return {
        ...state,
        entities: {
          chatComments: {
            ...state.entities.chatComments,
            [chatComment.id]: {
              ...chatComment,
              chatReplies: chatComment.chatReplies.filter(
                (reply) => reply.id !== action.id,
              ),
            },
          },
        },
      };
    }
    case "ADD_REACTION_TO_CHAT_COMMENT_SUCCESS": {
      return {
        ...state,
        entities: {
          chatComments: {
            ...state.entities.chatComments,
            [action.chatComment.id]: action.chatComment,
          },
        },
      };
    }
    case "ADD_REACTION_TO_CHAT_REPLY_SUCCESS": {
      return {
        ...state,
        entities: {
          chatComments: {
            ...state.entities.chatComments,
            [action.chatComment.id]: action.chatComment,
          },
        },
      };
    }
    case "SWITCH_CHAT_ROOM": {
      return initialState;
    }
    default:
      return state;
  }
}
