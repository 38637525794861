import { combineReducers } from "redux";
import { store } from "@/App";
import currentReducer from "@/shared/reducers/currentReducer";
import loadingHandlerReducer from "@/shared/reducers/loadingHandlerReducer";
import prefectureReducer from "@/shared/reducers/prefectureReducer";
import responseHandlerReducer from "@/shared/reducers/responseHandlerReducer";
import contactModalReducer from "@/signin/reducers/contactModalReducer";
import senderReducer from "@/signin/reducers/senderReducer";
import superUserReducer from "@/signin/reducers/superUserReducer";
import teamReducer from "@/signin/reducers/teamReducer";
import youtubeReviewLinkReducer from "@/signin/reducers/youtubeReviewLinkReducer";
import activeReviewReducer from "@/user/reducers/entities/ActiveReviewReducer";
import archiveRecommendProjectReducer from "@/user/reducers/entities/ArchiveRecommendProjectReducer";
import archivedProjectReducer from "@/user/reducers/entities/ArchivedProjectReducer";
import { assetCompositionReducer } from "@/user/reducers/entities/AssetCompositionReducer";
import { assetSearchReducer } from "@/user/reducers/entities/AssetSearchReducer";
import assignerSelectionReducer from "@/user/reducers/entities/AssignerSelectionReducer";
import blankPostedAtProjectCountReducer from "@/user/reducers/entities/BlankPostedAtProjectCountReducer";
import channelReducer from "@/user/reducers/entities/ChannelReducer";
import chatCommentFileReducer from "@/user/reducers/entities/ChatCommentFileReducer";
import chatCommentReducer from "@/user/reducers/entities/ChatCommentReducer";
import chatRoomReducer from "@/user/reducers/entities/ChatRoomReducer";
import completeNumberReducer from "@/user/reducers/entities/CompleteNumberReducer";
import completeTargetNumberReducer from "@/user/reducers/entities/CompleteTargetNumberReducer";
import { contractReducer } from "@/user/reducers/entities/ContractReducer";
import creativeFileDownloadLinkSettingReducer from "@/user/reducers/entities/CreativeFileDownloadLinkSettingReducer";
import creativeFileReadUserReducer from "@/user/reducers/entities/CreativeFileReadUserReducer";
import creativeFileReducer from "@/user/reducers/entities/CreativeFileReducer";
import creativeFileTypeReducer from "@/user/reducers/entities/CreativeFileTypeReducer";
import creativeFileVersionReducer from "@/user/reducers/entities/CreativeFileVersionReducer";
import creativeFolderReducer from "@/user/reducers/entities/CreativeFolderReducer";
import creativeRepositoryReducer from "@/user/reducers/entities/CreativeRepositoryReducer";
import customNoteReducer from "@/user/reducers/entities/CustomNoteReducer";
import dictionaryReducer from "@/user/reducers/entities/DictionaryReducer";
import equipmentCategoryReducer from "@/user/reducers/entities/EquipmentCategoryReducer";
import { equipmentProjectSelectionReducer } from "@/user/reducers/entities/EquipmentProjectSelectionReducer";
import equipmentReducer from "@/user/reducers/entities/EquipmentReducer";
import equipmentReservationReducer from "@/user/reducers/entities/EquipmentReservationReducer";
import equipmentReservationSelectionReducer from "@/user/reducers/entities/EquipmentReservationSelectionReducer";
import equipmentSelectionReducer from "@/user/reducers/entities/EquipmentSelectionReducer";
import equipmentSetReducer from "@/user/reducers/entities/EquipmentSetReducer";
import filePropertyReducer from "@/user/reducers/entities/FilePropertyReducer";
import holidaySettingReducer from "@/user/reducers/entities/HolidaySettingReducer";
import icsLinkReducer from "@/user/reducers/entities/IcsLinkReducer";
import invitationTokenSenderReducer from "@/user/reducers/entities/InvitationTokenSenderReducer";
import ipWhitelistSettingReducer from "@/user/reducers/entities/IpWhitelistSettingReducer";
import joinRequestReducer from "@/user/reducers/entities/JoinRequestReducer";
import kpiProjectReducer from "@/user/reducers/entities/KpiProjectReducer";
import kpiSummaryReducer from "@/user/reducers/entities/KpiSummaryReducer";
import myProjectReducer from "@/user/reducers/entities/MyProjectReducer";
import notificationReducer from "@/user/reducers/entities/NotificationReducer";
import oauthTokenReducer from "@/user/reducers/entities/OauthTokenReducer";
import oneTimeTokenReducer from "@/user/reducers/entities/OneTimeTokenReducer";
import outsourceProfessionReducer from "@/user/reducers/entities/OutsourceProfessionReducer";
import partnerReducer from "@/user/reducers/entities/PartnerReducer";
import personalSummaryReducer from "@/user/reducers/entities/PersonalSummaryReducer";
import planReducer from "@/user/reducers/entities/PlanReducer";
import pluginSettingReducer from "@/user/reducers/entities/PluginSettingReducer";
import processGroupReducer from "@/user/reducers/entities/ProcessGroupReducer";
import processSettingIconReducer from "@/user/reducers/entities/ProcessSettingIconReducer";
import processSettingReducer from "@/user/reducers/entities/ProcessSettingReducer";
import projectCategoryReducer from "@/user/reducers/entities/ProjectCategoryReducer";
import projectReducer from "@/user/reducers/entities/ProjectReducer";
import projectStatusReducer from "@/user/reducers/entities/ProjectStatusReducer";
import projectTagReducer from "@/user/reducers/entities/ProjectTagReducer";
import releaseNoteReducer from "@/user/reducers/entities/ReleaseNoteReducer";
import reviewCommentReducer from "@/user/reducers/entities/ReviewCommentReducer";
import reviewCsvDownloadSettingReducer from "@/user/reducers/entities/ReviewCsvDownloadSettingReducer";
import reviewLinkSettingReducer from "@/user/reducers/entities/ReviewLinkSettingReducer";
import reviewReducer from "@/user/reducers/entities/ReviewReducer";
import roleReducer from "@/user/reducers/entities/RoleReducer";
import scheduleTaskEquipmentReducer from "@/user/reducers/entities/ScheduleTaskEquipmentReducer";
import scheduleTaskReducer from "@/user/reducers/entities/ScheduleTaskReducer";
import shareIdeaBoxReducer from "@/user/reducers/entities/ShareIdeaBoxReducer";
import shareIdeaBoxSettingReducer from "@/user/reducers/entities/ShareIdeaBoxSettingReducer";
import shareUserCustomViewConfigReducer from "@/user/reducers/entities/ShareUserCustomViewConfigReducer";
import shareUserCustomViewProjectReducer from "@/user/reducers/entities/ShareUserCustomViewProjectReducer";
import shareViewSettingReducer from "@/user/reducers/entities/ShareViewSettingReducer";
import slackChannelReducer from "@/user/reducers/entities/SlackChannelReducer";
import softEmptyCreativeFileReducer from "@/user/reducers/entities/SoftEmptyCreativeFileReducer";
import usecaseAbilityReducer from "@/user/reducers/entities/UsecaseAbilityReducer";
import userCustomViewReducer from "@/user/reducers/entities/UserCustomViewReducer";
import userGroupReducer from "@/user/reducers/entities/UserGroupReducer";
import userNotificationSettingReducer from "@/user/reducers/entities/UserNotificationSettingReducer";
import userReducer from "@/user/reducers/entities/UserReducer";
import userSummaryReducer from "@/user/reducers/entities/UserSummaryReducer";
import shareIdeaBoxConfigReducer from "@/user/reducers/entities/shareIdeaBoxConfigReducer";
import chatThreadReducer from "@/user/reducers/ui/ChatThreadReducer";
import CustomViewReducer from "@/user/reducers/ui/CustomViewReducer";
import equipmentReservationPopupReducer from "@/user/reducers/ui/EquipmentReservationPopupReducer";
import modalReducer from "@/user/reducers/ui/ModalReducer";
import processManagementReducer from "@/user/reducers/ui/ProcessManagementReducer";
import reviewPasswordReducer from "@/user/reducers/ui/ReviewPasswordReducer";
import reviewViewReducer from "@/user/reducers/ui/ReviewViewReducer";
import drawingReducer from "@/user/reducers/ui/drawingReducer";
import globalAppReducer from "@/user/reducers/ui/globalAppReducer";

export type AppState = ReturnType<typeof rootReducer>;
declare module "react-redux" {
  interface DefaultRootState extends AppState {}
}
const rootReducer = combineReducers({
  drawingReducer,
  globalAppReducer,
  currentReducer,
  prefectureReducer,
  responseHandlerReducer,
  loadingHandlerReducer,
  assetCompositionReducer,
  assetSearchReducer,
  assignerSelectionReducer,
  completeTargetNumberReducer,
  contractReducer,
  projectReducer,
  archivedProjectReducer,
  equipmentReducer,
  equipmentCategoryReducer,
  equipmentReservationReducer,
  equipmentReservationSelectionReducer,
  equipmentReservationPopupReducer,
  equipmentSetReducer,
  equipmentProjectSelectionReducer,
  equipmentSelectionReducer,
  dictionaryReducer,
  creativeRepositoryReducer,
  creativeFileReducer,
  softEmptyCreativeFileReducer,
  creativeFileReadUserReducer,
  creativeFileTypeReducer,
  creativeFolderReducer,
  creativeFileVersionReducer,
  activeReviewReducer,
  notificationReducer,
  joinRequestReducer,
  invitationTokenSenderReducer,
  holidaySettingReducer,
  ipWhitelistSettingReducer,
  userCustomViewReducer,
  oneTimeTokenReducer,
  outsourceProfessionReducer,
  partnerReducer,
  filePropertyReducer,
  processGroupReducer,
  processSettingIconReducer,
  processSettingReducer,
  shareUserCustomViewConfigReducer,
  planReducer,
  pluginSettingReducer,
  projectCategoryReducer,
  projectTagReducer,
  reviewReducer,
  shareUserCustomViewProjectReducer,
  archiveRecommendProjectReducer,
  projectStatusReducer,
  modalReducer,
  reviewCommentReducer,
  reviewCsvDownloadSettingReducer,
  completeNumberReducer,
  userReducer,
  myProjectReducer,
  kpiSummaryReducer,
  personalSummaryReducer,
  userSummaryReducer,
  kpiProjectReducer,
  blankPostedAtProjectCountReducer,
  releaseNoteReducer,
  channelReducer,
  usecaseAbilityReducer,
  shareViewSettingReducer,
  scheduleTaskReducer,
  scheduleTaskEquipmentReducer,
  shareIdeaBoxSettingReducer,
  shareIdeaBoxConfigReducer,
  shareIdeaBoxReducer,
  roleReducer,
  creativeFileDownloadLinkSettingReducer,
  chatRoomReducer,
  chatCommentReducer,
  chatCommentFileReducer,
  userGroupReducer,
  chatThreadReducer,
  reviewPasswordReducer,
  reviewLinkSettingReducer,
  oauthTokenReducer,
  slackChannelReducer,
  processManagementReducer,
  CustomViewReducer,
  userNotificationSettingReducer,
  customNoteReducer,
  contactModalReducer,
  youtubeReviewLinkReducer,
  senderReducer,
  superUserReducer,
  teamReducer,
  reviewViewReducer,
  icsLinkReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type RootReducer = ReturnType<typeof rootReducer>;
export default rootReducer;
