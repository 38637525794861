import ShareIdeaBoxConfigRes from "@/types/res/ShareIdeaBoxConfigRes";

export type ShareIdeaBoxConfigState = {
  entities: {
    shareIdeaBoxConfigs: {
      [key: number]: ShareIdeaBoxConfigRes;
    };
  };
  result: number[];
};
const initialState = {} as ShareIdeaBoxConfigState;
export default function shareIdeaBoxConfigReducer(
  state = initialState,
  action: {
    type: string;
    data: ShareIdeaBoxConfigState;
  },
) {
  switch (action.type) {
    case "GET_SHARE_IDEA_BOX_CONFIGS_SUCCESS":
      return action.data;
    default:
      return state;
  }
}
