export type CustomViewState = {
  isHiddenAction: boolean;
};
const initialState: CustomViewState = {
  isHiddenAction: false,
};
export default function CustomViewReducer(
  state = initialState,
  action: {
    type: string;
  },
) {
  switch (action.type) {
    case "SET_IS_HIDDEN_VIEW":
      return {
        ...state,
        isHiddenAction: true,
      };
    case "RESET_IS_HIDDEN_VIEW":
      return {
        ...state,
        isHiddenAction: false,
      };
    default: {
      return state;
    }
  }
}
