import UserRes from "@/types/res/UserRes";

export type CreativeFileReadUserState = {
  entities: {
    users: { [key: number]: UserRes };
  };
  result: number[];
};

const initialState = {} as CreativeFileReadUserState;

export default function creativeFileReadUserReducer(
  state = initialState,
  action: {
    type: string;
    data: CreativeFileReadUserState;
  },
) {
  switch (action.type) {
    case "GET_READ_USERS_SUCCESS":
      return action.data;
    default:
      return state;
  }
}
