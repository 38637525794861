import PartnerRes from "@/types/res/PartnerRes";

export type PartnerState = {
  entities: {
    partners: { [key: number]: PartnerRes };
  };
  result: number[];
};
const initialState = {} as PartnerState;
export default function partnerReducer(
  state = initialState,
  action: {
    type: string;
    data: PartnerState;
    id: number;
  },
) {
  switch (action.type) {
    case "GET_PARTNERS_SUCCESS":
      return action.data;
    case "GET_PARTNER_SUCCESS":
      return {
        ...state,
        entities: {
          partners: {
            ...state.entities?.partners,
            ...action.data.entities.partners,
          },
        },
        result: [...(state?.result || []), ...action.data.result],
      };
    case "CREATE_PARTNER_SUCCESS": {
      return {
        ...state,
        entities: {
          partners: {
            ...state.entities.partners,
            ...action.data.entities.partners,
          },
        },
        result: [...state.result, ...action.data.result],
      };
    }
    case "UPDATE_PARTNER_SUCCESS": {
      return {
        ...state,
        entities: {
          partners: {
            ...state.entities.partners,
            ...action.data.entities.partners,
          },
        },
        result: state.result,
      };
    }
    case "DELETE_PARTNER_SUCCESS": {
      delete state.entities.partners[action.id];
      return {
        entities: {
          ...state.entities,
        },
        result: state.result.filter((r) => r !== action.id),
      };
    }
    default:
      return state;
  }
}
