import ReleaseNoteRes from "@/types/res/ReleaseNoteRes";

export type ReleaseNoteState = {
  entities: {
    releaseNotes: { [key: number]: ReleaseNoteRes };
  };
  result: number[];
};
const initialState = {} as ReleaseNoteState;
export default function releaseNoteReducer(
  state = initialState,
  action: {
    type: string;
    data: ReleaseNoteState;
  },
) {
  switch (action.type) {
    case "GET_RELEASE_NOTES_SUCCESS": {
      return action.data;
    }
    default:
      return state;
  }
}
