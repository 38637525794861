import AssignerRes from "@/types/res/AssignerRes";

export type AssignerSelectionState = {
  assignerSelection: AssignerRes;
};
const initialState = {
  assignerSelection: {
    users: [],
    partners: [],
  },
} as AssignerSelectionState;
export default function assignerSelectionReducer(
  state = initialState,
  action: {
    type: string;
    data: AssignerSelectionState;
  },
) {
  switch (action.type) {
    case "GET_ASSIGNER_SELECTIONS_SUCCESS":
      return { ...action.data };
    case "CLEAR_ASSIGNER_SELECTIONS": {
      return initialState;
    }
    default:
      return state;
  }
}
