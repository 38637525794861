export type ReviewPasswordState = {
  password: string;
};
const initialState: ReviewPasswordState = {
  password: "",
};
export default function reviewPasswordReducer(
  state = initialState,
  action: {
    type: string;
    password: string;
  },
) {
  switch (action.type) {
    case "SET_PASSWORD": {
      return {
        ...state,
        password: action.password,
      };
    }
    default:
      return state;
  }
}
