import * as React from "react";
import { Puff } from "react-loader-spinner";
import styled from "styled-components";
import COLORs from "@/shared/libs/color";

const Container = styled.div`
  position: absolute;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`;
type Props = {
  color?: string;
  height?: number;
  width?: number;
};

const PageLoading: React.FC<Props> = (props: Props) => {
  return (
    <Container>
      <Puff
        color={props.color || COLORs.loading}
        height={props.height || 64}
        width={props.width || 64}
      />
    </Container>
  );
};
export default PageLoading;
