import IcsLinkRes from "@/types/res/IcsLinkRes";

export type IcsLinkState = {
  entities: {
    icsLinks: { [key: number]: IcsLinkRes };
  };
  result: number[];
};
const initialState = {} as IcsLinkState;
export default function icsLinkReducer(
  state = initialState,
  action: {
    type: string;
    id: number;
    data: IcsLinkState;
  },
) {
  switch (action.type) {
    case "GET_ICS_LINKS_SUCCESS":
      return action.data;
    case "DELETE_ICS_LINK_SUCCESS":
      delete state.entities.icsLinks[action.id];
      return {
        entities: {
          icsLinks: {
            ...state.entities.icsLinks,
          },
        },
        result: state.result.filter((id) => id !== action.id),
      };
    default:
      return state;
  }
}
