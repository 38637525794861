import {
  ProcessSettingNormalizeRes,
  ProcessSettingProcessRes,
} from "@/types/res/ProcessSettingRes";
export type ProcessSettingState = {
  entities: {
    process: { [key: string]: ProcessSettingProcessRes };
    willUpdateProcess: { [key: string]: ProcessSettingProcessRes } | null;
    processSetting: ProcessSettingNormalizeRes;
  };
  result: number[];
};
const initialState = {
  entities: { process: {}, processSetting: {}, willUpdateProcess: null },
  result: [],
} as ProcessSettingState;
export default function processSettingReducer(
  state = initialState,
  action: {
    type: string;
    data: ProcessSettingState;
    identifier: string;
    identifiers: string[];
    index: number;
    name: string;
    value: any;
  },
) {
  switch (action.type) {
    case "GET_PROCESS_SETTING_SUCCESS": {
      return { ...action.data };
    }
    case "CREATE_PROCESS_SETTING_SUCCESS": {
      return { ...action.data };
    }
    case "UPDATE_PROCESS_SETTING_SUCCESS": {
      return { ...action.data };
    }
    case "DELETE_PROCESS_SETTING_SUCCESS": {
      delete state.entities.process[action.identifier];
      return {
        entities: {
          process: state.entities.process,
          processSetting: {
            ...state.entities.processSetting,
            process: state.entities.processSetting.process.filter(
              (p) => p !== action.identifier,
            ),
          },
          willUpdateProcess: state.entities.willUpdateProcess,
        },
        result: state.result,
      };
    }
    case "SORT_PROCESS_SETTING_SUCCESS": {
      return {
        entities: {
          process: state.entities.process,
          processSetting: {
            ...state.entities.processSetting,
            process: action.identifiers,
            willUpdateProcess: action.identifiers,
          },
          willUpdateProcess: state.entities.willUpdateProcess,
        },
        result: state.result,
      };
    }
    case "TEMPORARY_ADD_PROCESS_SETTING": {
      const initialProcess: ProcessSettingProcessRes = {
        identifier: action.identifier,
        name: "新規作成",
        icon: "file",
        iconUrl: "",
        assignable: false,
        isDisplay: true,
        isPersist: false,
        completable: false,
        description: "",
        schedulable: false,
        completeType: 0,
        memorable: false,
        isKanbanRow: false,
        filePropertyId: null,
        autoDeleteFilePropertyIds: [],
        autoCompletableOnFileUpload: false,
        processGroupId: null,
      };
      const process = [...state.entities.processSetting.process];
      process.splice(action.index + 1, 0, action.identifier);
      return {
        entities: {
          process: {
            ...state.entities.process,
            [action.identifier]: initialProcess,
          },
          processSetting: {
            ...state.entities.processSetting,
            process: process,
            willUpdateProcess: state.entities.processSetting.willUpdateProcess
              ? process
              : state.entities.processSetting.willUpdateProcess,
          },
          willUpdateProcess: state.entities.willUpdateProcess
            ? {
              ...state.entities.willUpdateProcess,
              [action.identifier]: initialProcess,
            }
            : state.entities.willUpdateProcess,
        },
        result: state.result,
      };
    }
    case "TEMPORARY_UPDATE_PROCESS_SETTING": {
      return {
        entities: {
          process: {
            ...state.entities.process,
            [action.identifier]: {
              ...state.entities.process[action.identifier],
              [action.name]: action.value,
            },
          },
          willUpdateProcess: state.entities.willUpdateProcess
            ? {
              ...state.entities.willUpdateProcess,
              [action.identifier]: {
                ...state.entities.willUpdateProcess[action.identifier],
                [action.name]: action.value,
              },
            }
            : state.entities.willUpdateProcess,
          processSetting: state.entities.processSetting,
        },
        result: state.result,
      };
    }
    default:
      return state;
  }
}
