import React from "react";
import axios from "axios";
import dayjs, { extend } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import JapaneseHolidays from "japanese-holidays";
import "dayjs/locale/ja";
import * as wanakana from "wanakana";
import COLORs from "@/shared/libs/color";
import { ProcessSettingProcessRes } from "@/types/res/ProcessSettingRes";
import TeamHolidayRes from "@/types/res/TeamHolidayRes";

dayjs.locale("ja");
extend(relativeTime);
extend(customParseFormat);
export function isPresent(obj: any) {
  return (
    obj !== "" &&
    obj !== null &&
    obj !== undefined &&
    (typeof obj === "function" ||
      Object.keys(obj).length !== 0 ||
      typeof obj !== "object")
  );
}
export function addItem(array: any[], item: any) {
  return array.concat(item);
}
export function removeItem(array: any[], item: any) {
  return array.filter((v) =>
    Array.isArray(item) ? !item.includes(v) : v !== item,
  );
}
export function isDuplicate(arrayA: any[], arrayB: any[]) {
  return (
    [...new Set([...arrayA, ...arrayB])].length !==
    arrayA.length + arrayB.length
  );
}
export function differenceSet(arrayA: any[], arrayB: any[]) {
  return arrayA.filter((a) => !arrayB.includes(a));
}
export function mergeDayTime(day: string, time: string) {
  if (!day || !time) return day;
  const timeObj = dayjs(time);
  const hour = timeObj.hour();
  const minute = timeObj.minute();
  return dayjs(day).startOf("day").hour(hour).minute(minute).format();
}
export function isDatetimeOverlap(
  startX: string,
  endX: string,
  startY: string,
  endY: string,
) {
  return (
    dayjs(startX).isBefore(dayjs(endY)) && dayjs(endX).isAfter(dayjs(startY))
  );
}
export function formatMMSSfromFloat(float: number) {
  if (float !== 0 && !float) return "-";
  const min = Math.floor(float / 60);
  const sec = Math.round(float - min * 60);
  const minF = min < 10 ? `0${min}` : min;
  const secF = sec < 10 ? `0${sec}` : sec;
  return `${minF}:${secF}`;
}
function decimalPart(num: number, decDigits: number) {
  const decPart = num - (num >= 0 ? Math.floor(num) : Math.ceil(num));
  return decPart.toFixed(decDigits);
}
export function formatHHMMSSfromFloat(float: number) {
  if (float !== 0 && !float) return "-";

  const hour = Math.floor(float / 3600);
  const min = Math.floor((float - hour * 3600) / 60);
  const sec = Math.floor(float - hour * 3600 - min * 60);

  const hourF = hour < 10 ? `0${hour}` : hour;
  const minF = min < 10 ? `0${min}` : min;
  const secF = sec < 10 ? `0${sec}` : sec;

  return `${hourF}:${minF}:${secF}`;
}
export function formatHHMMSSFFfromFloat(float: number) {
  if (float !== 0 && !float) return "-";

  const hour = Math.floor(float / 3600);
  const min = Math.floor((float - hour * 3600) / 60);
  const sec = Math.floor(float - hour * 3600 - min * 60);
  const msec = Number(decimalPart(float, 2)) * 100;
  const msecStr = msec < 10 ? `${msec}`.slice(0, 1) : `${msec}`.slice(0, 2);

  const hourF = hour < 10 ? `0${hour}` : hour;
  const minF = min < 10 ? `0${min}` : min;
  const secF = sec < 10 ? `0${sec}` : sec;
  const msecF = msec < 10 ? `0${msecStr}` : msecStr;

  return `${hourF}:${minF}:${secF}:${msecF}`;
}
export function formatPlayTime(float: number) {
  if (float !== 0 && !float) return "-";

  const hour = Math.floor(float / 3600);
  const min = Math.floor((float - hour * 3600) / 60);
  const sec = Math.floor(float - hour * 3600 - min * 60);

  const hourF = hour ? `${hour}:` : "";
  const minF = min ? `${min}:` : "0:";
  const secF = sec < 10 ? `0${sec}` : sec;
  return `${hourF}${minF}${secF}`;
}
export function isKeyManipulationInvalid() {
  // TODO Drawingで四角や丸などの図形を矢印移動させるときにこのロジックに入ってしまうため対応が必要
  const tagName = document.activeElement.tagName;
  const typeAttr = document.activeElement.getAttribute("type");

  return (
    tagName === "TEXTAREA" ||
    (tagName === "INPUT" && [null, "text"].includes(typeAttr))
  );
}
export function isKeyManipulationInvalidExceptInput() {
  const tagName = document.activeElement.tagName;

  return tagName === "TEXTAREA";
}
export function parseDateStringFormat(date: string, format: string) {
  return dayjs(date, format, "ja").format();
}

export function formatDate(date: dayjs.ConfigType) {
  return date ? dayjs(date).format() : "";
}
export function formatDisplayJPNDate(date: dayjs.ConfigType) {
  return date ? dayjs(date).format("YYYY年M月D日（ddd）") : "";
}
export function formatYearMonthJPNDate(date: dayjs.ConfigType) {
  return date ? dayjs(date).format("YYYY年M月") : "";
}
export function formatDisplayJPNYearMonth(date: dayjs.ConfigType) {
  return date ? dayjs(date).format("YYYY年M月") : "";
}
export function formatPeriodedDate(date: dayjs.ConfigType) {
  return date ? dayjs(date).format("YYYY.M.D H:mm") : "";
}
export function formatPeriodedYMDDate(date: dayjs.ConfigType) {
  return date ? dayjs(date).format("YYYY.MM.DD") : "";
}
export function formatHyphenedYMDDate(date: dayjs.ConfigType) {
  return date ? dayjs(date).format("YYYY-MM-DD") : "";
}
export function formatHMDate(date: dayjs.ConfigType) {
  return date ? dayjs(date).format("H:mm") : "";
}
export function formatIncludeDayOfWeek(date: dayjs.ConfigType) {
  return date ? dayjs(date).format("M.DD（ddd）") : "";
}
export function formatMonthDay(date: dayjs.ConfigType) {
  return date ? dayjs(date).format("M.D") : "";
}
export function formatDay(date: dayjs.ConfigType) {
  return date ? dayjs(date).format("DD") : "";
}
export function formatFull(
  startDate: dayjs.ConfigType,
  startTime: dayjs.ConfigType,
  endDate: dayjs.ConfigType,
  endTime: dayjs.ConfigType,
) {
  const stDate = startDate ? dayjs(startDate).format("M.DD") : "";
  const stTime = startTime ? dayjs(startTime).format(" H:mm") : "";
  const edDate = endDate ? dayjs(endDate).format(" → M.DD") : "";
  const edTime = endTime ? dayjs(endTime).format(" H:mm") : "";

  return `${stDate}${stTime}${edDate}${edTime}`;
}
export function getFormatMonth(date: dayjs.ConfigType) {
  return date ? dayjs(date).format("M") : "";
}
export function getFormatDay(date: dayjs.ConfigType) {
  return date ? dayjs(date).format("D") : "";
}
export function getFormatWeekday(date: dayjs.ConfigType) {
  return date ? dayjs(date).format("ddd") : "";
}
export function addDay(date: dayjs.ConfigType, day: number) {
  return dayjs(date).add(day, "day");
}
export function isBeforeDate(dateX: dayjs.ConfigType, dateY: dayjs.ConfigType) {
  return dayjs(dateX).isBefore(dateY);
}
export function isAfterDate(dateX: dayjs.ConfigType, dateY: dayjs.ConfigType) {
  return dayjs(dateX).isAfter(dateY);
}
export function isBeforeDay(dateX: dayjs.ConfigType, dateY: dayjs.ConfigType) {
  return dayjs(dateX).isBefore(dateY, "day");
}
export function isAfterDay(dateX: dayjs.ConfigType, dateY: dayjs.ConfigType) {
  return dayjs(dateX).isAfter(dateY, "day");
}
export function isSameDay(dateX: dayjs.ConfigType, dateY: dayjs.ConfigType) {
  return dayjs(dateX).isSame(dateY, "day");
}
export function today() {
  return dayjs();
}
export function toDate(date: dayjs.ConfigType) {
  return date ? dayjs(date).toDate() : null;
}
export function formatDatetimeRange(start: Date, end: Date) {
  const startObj = dayjs(start).format();
  const endObj = dayjs(end).format();
  return end
    ? `${formatPeriodedDate(startObj)} → ${formatPeriodedDate(endObj)}`
    : formatPeriodedDate(startObj);
}
export function getDay(date: Date) {
  return dayjs(date).day();
}
export function relativeTimeText(date: dayjs.ConfigType) {
  return dayjs(date).fromNow();
}
export function isTouchDevice() {
  return (
    (!!(
      typeof window !== "undefined" &&
      ("ontouchstart" in window ||
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (window.DocumentTouch &&
          typeof document !== "undefined" &&
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          document instanceof window.DocumentTouch))
    ) ||
      !!(typeof navigator !== "undefined" && navigator.maxTouchPoints)) &&
    window.matchMedia("(pointer: coarse)").matches
  );
}
export function boldMentionAndLinkURL(text: string, targetBlank = false) {
  if (!isPresent(text)) return text;

  const mentionReg = /@{(.+?)}/g;
  const mention = `${text}`.replace(mentionReg, `<b>@$1</b>`);

  return autoLink(mention, targetBlank);
}

export function autoLink(text: string, targetBlank = false) {
  if (!isPresent(text)) return text;

  const exp =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
  const str = `${text}`.replace(
    exp,
    `<a target='${targetBlank ? "_blank" : "_self"}' href='$1'>$1</a>`,
  );

  return <div dangerouslySetInnerHTML={{ __html: str }} />;
}
export function extractFirstLink(text: string) {
  const exp =
    /(\b(https?):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
  return text.match(exp)[0];
}
export function hex2rgb(hex: string) {
  if (hex.slice(0, 1) === "#") hex = hex.slice(1);
  if (hex.length === 3)
    hex =
      hex.slice(0, 1) +
      hex.slice(0, 1) +
      hex.slice(1, 2) +
      hex.slice(1, 2) +
      hex.slice(2, 3) +
      hex.slice(2, 3);

  return [hex.slice(0, 2), hex.slice(2, 4), hex.slice(4, 6)].map((str) => {
    return parseInt(str, 16);
  });
}
export function extractYouTubeVideoId(text: string) {
  const t = `${text}`
    .replace("https://www.youtube.com/watch?v=", "")
    .replace("https://youtu.be/", "")
    .replace("https://www.youtube.com/embed/", "")
    .replace("https://www.youtube.com/shorts/", "")
    .replace("https://youtube.com/shorts/", "")
    .split("&")[0];
  return t.includes("?") ? t.substr(0, t.indexOf("?")) : t;
}
export function extractVimeoVideoId(text: string) {
  return (
    (text.match(/^.*https:\/\/vimeo\.com\/manage\/videos\/(\d+).*/) || [])[1] ||
    (text.match(/^.*https:\/\/vimeo\.com\/(\d+).*/) || [])[1] ||
    (text.match(/^.*https:\/\/player\.vimeo\.com\/video\/(\d+).*/) || [])[1]
  );
}
export function extractYouTubeChannelId(text: string) {
  if (!text) return null;
  const regexp = /^https?:\/\/(www\.)?youtube\.com\/.+/;
  if (!regexp.test(text)) {
    alert("YouTubeチャンネルのURLを指定してください。");
    return null;
  }
  const t = `${text}`
    .replace("https://www.youtube.com/", "")
    .replace("https://youtube.com/", "")
    .split("/")[0]
    .split("?")[0];
  return t;
}
export function groupBy<K, V>(
  array: readonly V[],
  getKey: (cur: V, idx: number, src: readonly V[]) => K,
) {
  return Array.from(
    array.reduce((map, cur, idx, src) => {
      const key = getKey(cur, idx, src);
      const list = map.get(key);
      if (list) list.push(cur);
      else map.set(key, [cur]);
      return map;
    }, new Map<K, V[]>()),
  );
}
export function parseHexToDecimal(text: string) {
  const hex = `${text}`.replace("#", "");
  const firstDecimal = parseInt(hex.substr(0, 2), 16);
  const secondDecimal = parseInt(hex.substr(2, 2), 16);
  const thirdDecimal = parseInt(hex.substr(4, 2), 16);
  return `${firstDecimal}, ${secondDecimal}, ${thirdDecimal}`;
}
export function isHoliday(
  day: dayjs.Dayjs,
  payload: {
    sunday: boolean;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    teamHolidays: TeamHolidayRes[];
  },
) {
  const wday = day.day();
  if (
    (wday === 0 && payload.sunday) ||
    (wday === 1 && payload.monday) ||
    (wday === 2 && payload.tuesday) ||
    (wday === 3 && payload.wednesday) ||
    (wday === 4 && payload.thursday) ||
    (wday === 5 && payload.friday) ||
    (wday === 6 && payload.saturday) ||
    JapaneseHolidays.isHoliday(day.toDate())
  ) {
    return true;
  }
  return payload.teamHolidays.some((holiday) => {
    return dayjs(holiday.date).isSame(day, "day");
  });
}
export function roundOffDecimalPlace(num: number, decDigits: number) {
  const digits = 10 ** decDigits;
  return Math.round(num * digits) / digits;
}
export function convertBase64(url: string) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(xhr.response);
    };

    xhr.open("GET", url);
    xhr.send();
  });
}
export function getTextColorByBgContrast(rgb: string) {
  const hex = `${rgb}`.replace("#", "");
  const firstDecimal = parseInt(hex.substr(0, 2), 16);
  const secondDecimal = parseInt(hex.substr(2, 2), 16);
  const thirdDecimal = parseInt(hex.substr(4, 2), 16);
  // NOTE: https://ja.wikipedia.org/wiki/YUV
  const brightness =
    firstDecimal * 0.299 + secondDecimal * 0.587 + thirdDecimal * 0.114;
  return brightness >= 140 ? COLORs.black : COLORs.white;
}
export function uniqObjectArray(array: any[], key: string) {
  return [...new Map(array.map((item) => [item[key], item])).values()];
}
export function formatNumber(text: string | number) {
  if (text !== 0 && !text) return null;
  const formatter = new Intl.NumberFormat("ja-JP");
  return formatter.format(Number(text));
}
export function isEqualArray(arrayA: any[], arrayB: any[]) {
  let i = arrayA.length;
  if (i !== arrayB.length) return false;

  while (i--) {
    if (arrayA[i] !== arrayB[i]) return false;
  }
  return true;
}
export function ambiguousPartialMatch(targetStr: string, searchStr: string) {
  if (!searchStr) return true;
  if (!targetStr) return false;

  const searchStrRomaji = wanakana.toRomaji(searchStr.toString()).toLowerCase();
  const targetStrRomaji = wanakana.toRomaji(targetStr.toString()).toLowerCase();

  return targetStrRomaji.indexOf(searchStrRomaji) !== -1;
}

export async function isImgUrl(url: string) {
  if (!url) return false;

  // NOTE: imgタグでブラウザ表示できるもののみとする
  //   例. image/vnd.adobe.photoshop は含めないようにする
  const imageMimeTypes = [
    "image/png",
    "image/gif",
    "image/vnd.microsoft.icon",
    "image/jpeg",
    "image/svg+xml",
    "image/tiff",
    "image/webp",
  ];
  if (url.startsWith("http")) {
    const instance = axios.create({
      baseURL: import.meta.env.VITE_API_BASE,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": import.meta.env.VITE_HOST,
        Accept: "application/json",
      },
      withCredentials: true,
    });
    return instance.get(url).then((res) => {
      return imageMimeTypes.some((mimeType) =>
        res.headers["content-type"].startsWith(mimeType),
      );
    });
  } else {
    return imageMimeTypes.some((mimeType) =>
      url.startsWith(`data:${mimeType}`),
    );
  }
}

export function extractFileNameFromUrl(url: string) {
  if (!url) return url;
  return decodeURI(url.match(".+/(.+?)([?#;].*)?$")[1]);
}

export function projectStatusJPString(
  process: { [key: string]: ProcessSettingProcessRes },
  currentStatusProcess: string,
  currentStatusState: "READY" | "NEED_AT" | "COMPLETE" | "",
) {
  const processName = process[currentStatusProcess]?.name || "";
  const state = (planStatusState as any)[currentStatusState] || "";
  return `${processName}${state}`;
}

export const planStatusState = {
  READY: "準備中",
  NEED_AT: "進行中",
  COMPLETE: "完了",
};
