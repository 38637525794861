import ScheduleTaskRes from "@/types/res/ScheduleTaskRes";

export type ScheduleTaskState = {
  scheduleTask: ScheduleTaskRes;
};
const initialState = {} as ScheduleTaskState;
export default function scheduleTaskReducer(
  state = initialState,
  action: {
    type: string;
    data: ScheduleTaskState;
  },
) {
  switch (action.type) {
    case "GET_SCHEDULE_TASK_SUCCESS":
      return action.data;
    default:
      return state;
  }
}
