import ProjectRes from "@/types/res/ProjectRes";

export type EquipmentProjectSelectionState = {
  entities: {
    projects: { [key: number]: ProjectRes };
  };
  result: number[];
};
const initialState = {} as EquipmentProjectSelectionState;
export function equipmentProjectSelectionReducer(
  state = initialState,
  action: {
    type: string;
    data: EquipmentProjectSelectionState;
  },
) {
  switch (action.type) {
    case "GET_SHALLOW_SCHEDULE_TREE_SUCCESS":
      return action.data;
    default:
      return state;
  }
}
