import ChatRoomRes from "@/types/res/ChatRoomRes";

export type ChatRoomState = {
  entities: {
    chatRooms: { [key: number]: ChatRoomRes };
  };
  result: number[];
};
const initialState = {} as ChatRoomState;
export default function chatRoomReducer(
  state = initialState,
  action: {
    type: string;
    data: ChatRoomState;
    id: number;
    chatRoomId: number;
    chatRoom: ChatRoomRes;
  },
) {
  switch (action.type) {
    case "GET_CHAT_ROOMS_SUCCESS":
      return action.data;
    case "CREATE_CHAT_ROOM_SUCCESS": {
      const chatRoom = action.chatRoom;
      return {
        ...state,
        entities: {
          chatRooms: {
            ...state.entities.chatRooms,
            [chatRoom.id]: chatRoom,
          },
        },
        result: [...state.result, chatRoom.id],
      };
    }
    case "UPDATE_CHAT_ROOM_SUCCESS": {
      return {
        ...state,
        entities: {
          chatRooms: {
            ...state.entities.chatRooms,
            [action.id]: action.chatRoom,
          },
        },
      };
    }
    case "DESTROY_CHAT_ROOM_SUCCESS": {
      delete state.entities.chatRooms[action.id];
      return {
        ...state,
        entities: {
          chatRooms: { ...state.entities.chatRooms },
        },
        result: state.result.filter((id) => id !== action.id),
      };
    }
    case "GET_CHAT_COMMENTS_SUCCESS":
      return {
        ...state,
        entities: {
          chatRooms: {
            ...state.entities.chatRooms,
            [action.chatRoomId]: {
              ...state.entities.chatRooms[action.chatRoomId],
              unreadCount: 0,
            },
          },
        },
      };
    default:
      return state;
  }
}
