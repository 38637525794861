type ReservationPopup = {
  open: boolean;
  id: number;
  position: Record<string, unknown>;
  selectableProject: boolean;
  /* Create用 */
  startedAt: string;
  endedAt: string;
  isAllDay: boolean;
};
export type EquipmentReservationPopupState = {
  reservationPopup: ReservationPopup;
};
const initialState = {
  reservationPopup: {
    open: false,
    id: 0,
    position: {},
    selectableProject: false,
    startedAt: "",
    endedAt: "",
    isAllDay: false,
  },
};
export default function equipmentReservationPopupReducer(
  state = initialState,
  action: {
    type: string;
    open: boolean;
    id: number;
    position: Record<string, unknown>;
    selectableProject: boolean;
    /* Create用 */
    startedAt: string;
    endedAt: string;
    isAllDay: boolean;
  },
) {
  switch (action.type) {
    case "OPEN_RESERVATION_POPUP": {
      return {
        ...state,
        reservationPopup: {
          ...state.reservationPopup,
          open: true,
          id: action.id,
          position: action.position,
          selectableProject: action.selectableProject,
        },
      };
    }
    case "OPEN_CREATE_RESERVATION_POPUP": {
      return {
        ...state,
        reservationPopup: {
          ...state.reservationPopup,
          open: true,
          id: action.id,
          position: action.position,
          selectableProject: action.selectableProject,
          startedAt: action.startedAt,
          endedAt: action.endedAt,
          isAllDay: action.isAllDay,
        },
      };
    }
    case "SET_CREATE_RESERVATION_POPUP_POSITION": {
      const popup = Object.assign({}, state.reservationPopup);
      if (popup.position) return state;
      popup.position = action.position;
      return {
        ...state,
        reservationPopup: popup,
      };
    }
    case "CLOSE_RESERVATION_POPUP": {
      return {
        ...state,
        reservationPopup: initialState.reservationPopup,
      };
    }
    default:
      return state;
  }
}
