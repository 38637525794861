import UsecaseAbilityRes from "@/types/res/UsecaseAbilityRes";

export type UsecaseAbilityState = {
  entities: { usecaseAbilities: { [key: number]: UsecaseAbilityRes } };
  result: number[];
};

const initialState = {} as UsecaseAbilityState;
export default function usecaseAbilityReducer(
  state = initialState,
  action: {
    type: string;
    data: UsecaseAbilityState;
  },
) {
  switch (action.type) {
    case "GET_USECASE_ABILITIES_SUCCESS":
      return action.data;
    default:
      return state;
  }
}
