import InvitationTokenSenderRes from "@/types/res/InvitationTokenSenderRes";

export type InvitationTokenSenderState = {
  entities: {
    invitationTokenSenders: { [key: number]: InvitationTokenSenderRes };
  };
  result: number[];
};
const initialState = {} as InvitationTokenSenderState;
export default function invitationTokenSenderReducer(
  state = initialState,
  action: {
    type: string;
    data: InvitationTokenSenderState;
  },
) {
  switch (action.type) {
    case "GET_SENDER_SUCCESS":
      return action.data;
    default:
      return state;
  }
}
