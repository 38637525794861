export type ChatThreadState = {
  chatCommentId: number;
};
const initialState: ChatThreadState = {
  chatCommentId: null,
};
export default function chatThreadReducer(
  state = initialState,
  action: {
    type: string;
    chatCommentId: number;
  },
) {
  switch (action.type) {
    case "OPEN_CHAT_THREAD": {
      return {
        ...state,
        chatCommentId: action.chatCommentId,
      };
    }
    case "CLOSE_CHAT_THREAD": {
      return {
        ...state,
        chatCommentId: null,
      };
    }
    case "DESTROY_CHAT_COMMENT_SUCCESS": {
      return {
        ...state,
        chatCommentId: null,
      };
    }
    case "DESTROY_CHAT_ROOM_SUCCESS": {
      return {
        ...state,
        chatCommentId: null,
      };
    }
    default:
      return state;
  }
}
