import ActiveReviewRes from "@/types/res/ActiveReviewRes";

export type ActiveReviewState = {
  entities: {
    activeReviews: { [key: number]: ActiveReviewRes };
  };
  result: number[];
};

const initialState = {} as ActiveReviewState;

export default function activeReviewReducer(
  state = initialState,
  action: {
    type: string;
    data: ActiveReviewState;
  },
) {
  switch (action.type) {
    case "GET_ACTIVE_REVIEWS_SUCCESS":
      return action.data;
    default:
      return state;
  }
}
