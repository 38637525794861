import CreativeFileDownloadLinkSettingRes from "@/types/res/CreativeFileDownloadLinkSettingRes";

export type CreativeFileDownloadLinkSettingState = {
  creativeFileDownloadLinkSetting: CreativeFileDownloadLinkSettingRes;
};
const initialState = {} as CreativeFileDownloadLinkSettingState;
export default function creativeFileDownloadLinkSettingReducer(
  state = initialState,
  action: {
    type: string;
    data: CreativeFileDownloadLinkSettingState;
  },
) {
  switch (action.type) {
    case "GET_CREATIVE_FILE_DOWNLOAD_LINK_SETTING_SUCCESS":
      return action.data;
    case "UPDATE_CREATIVE_FILE_DOWNLOAD_LINK_SETTING_SUCCESS":
      return action.data;
    default:
      return state;
  }
}
