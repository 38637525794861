import PersonalSummaryRes from "@/types/res/PersonalSummaryRes";

export type PersonalSummaryState = {
  entities: {
    personalSummaries: {
      [key: number]: PersonalSummaryRes;
    };
  };
  result: number[];
};
const initialState = {} as PersonalSummaryState;
export default function personalSummaryReducer(
  state = initialState,
  action: {
    type: string;
    data: PersonalSummaryState;
  },
) {
  switch (action.type) {
    case "SEARCH_PERSONAL_SUMMARY_SUCCESS":
      return action.data;
    default:
      return state;
  }
}
