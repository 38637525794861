import ExpirationTypeRes from "@/types/res/ExpirationTypeRes";
import OneTimeTokenRes from "@/types/res/OneTimeTokenRes";

type OneTimeTokenDataState = {
  entities: {
    oneTimeTokens: { [key: number]: OneTimeTokenRes };
    expirationTypes: { [key: number]: ExpirationTypeRes };
  };
  result?: number[];
};
type OneTimeTokenStatus = "valid" | "invalid" | "expired" | "not_found";
type OneTimeTokenLpStatus = "VALID" | "INVALID" | "LP_INVALID";
export type OneTimeTokenState = {
  shareLinkIds: number[];
  shareUserCustomViewLinkIds: number[];
  creativeFileDownloadLinkIds: number[];
  oneTimeTokenId: number;
  expirationTypeIds: number[];
  isLoading: boolean;
  status: OneTimeTokenStatus;
  lpStatus: OneTimeTokenLpStatus;
} & OneTimeTokenDataState;
const initialState: OneTimeTokenState = {
  entities: { oneTimeTokens: {}, expirationTypes: {} },
  shareLinkIds: [],
  shareUserCustomViewLinkIds: [],
  creativeFileDownloadLinkIds: [],
  oneTimeTokenId: null,
  expirationTypeIds: [],
  isLoading: true,
  status: null,
  lpStatus: null,
};
export default function oneTimeTokenReducer(
  state = initialState,
  action: {
    type: string;
    data: OneTimeTokenDataState;
    status: OneTimeTokenStatus;
    lpStatus: OneTimeTokenLpStatus;
    id: number;
  },
) {
  switch (action.type) {
    case "GET_EXPIRATION_TYPES_SUCCESS":
      return {
        ...state,
        entities: {
          ...state.entities,
          expirationTypes: {
            ...state.entities.expirationTypes,
            ...action.data.entities.expirationTypes,
          },
        },
        expirationTypeIds: action.data.result,
      };
    case "GET_SHARE_LINKS_SUCCESS":
      return {
        ...state,
        entities: {
          ...state.entities,
          oneTimeTokens: {
            ...state.entities.oneTimeTokens,
            ...action.data.entities.oneTimeTokens,
          },
        },
        shareLinkIds: action.data.result,
        isLoading: false,
      };
    case "GET_SHARE_USER_CUSTOM_VIEW_LINKS_SUCCESS":
      return {
        ...state,
        entities: {
          ...state.entities,
          oneTimeTokens: {
            ...state.entities.oneTimeTokens,
            ...action.data.entities.oneTimeTokens,
          },
        },
        shareUserCustomViewLinkIds: action.data.result,
      };
    case "GET_SHARE_LINKS_FAILURE":
      return {
        ...state,
        isLoading: false,
      };
    case "GET_CREATIVE_FILE_DOWNLOAD_LINKS_SUCCESS": {
      return {
        ...state,
        entities: {
          ...state.entities,
          oneTimeTokens: {
            ...state.entities.oneTimeTokens,
            ...action.data.entities.oneTimeTokens,
          },
        },
        creativeFileDownloadLinkIds: action.data.result,
      };
    }
    case "CREATE_ONE_TIME_TOKEN_SUCCESS":
      return {
        ...state,
        entities: {
          ...state.entities,
          oneTimeTokens: {
            ...state.entities.oneTimeTokens,
            ...action.data.entities.oneTimeTokens,
          },
        },
        oneTimeTokenId: action.data.result[0],
      };
    case "GET_ONE_TIME_TOKEN_STATUS":
      return {
        ...state,
        status: action.status,
      };
    case "GET_ONE_TIME_TOKEN_LP_STATUS":
      return {
        ...state,
        lpStatus: action.lpStatus,
      };
    case "CLEAR_ONE_TIME_TOKEN":
      return {
        ...state,
        oneTimeTokenId: null,
      };
    case "INVALIDATE_ONE_TIME_TOKEN_SUCCESS": {
      return {
        ...state,
        entities: {
          ...state.entities,
          oneTimeTokens: {
            ...state.entities.oneTimeTokens,
            [action.id]: {
              ...state.entities.oneTimeTokens[action.id],
              isValid: "無効",
            },
          },
        },
      };
    }
    default:
      return state;
  }
}
