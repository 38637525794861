export type ResponseHandlerState = {
  sucMsg: string;
  errMsg: string;
};
const initialState: ResponseHandlerState = {
  sucMsg: "",
  errMsg: "",
};

export default function responseHandlerReducer(
  state = initialState,
  action: { type: string; sucMsg?: string; errMsg?: string },
) {
  switch (action.type) {
    case "HANDLE_RESPONSE_SUCCESS":
      return {
        ...initialState,
        sucMsg: action.sucMsg,
      };
    case "HANDLE_RESPONSE_ERROR":
      return {
        ...initialState,
        errMsg: action.errMsg,
      };
    case "CLEAR_RESPONSE_MASSAGE":
      return initialState;
    default: {
      return state;
    }
  }
}
