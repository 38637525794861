import CompleteNumberRes from "@/types/res/CompleteNumberRes";

export type CompleteNumberState = {
  entities: {
    completeNumbers: { [key: number]: CompleteNumberRes };
  };
  result: number[];
};
const initialState = {} as CompleteNumberState;
export default function completeNumberReducer(
  state = initialState,
  action: {
    type: string;
    data: CompleteNumberState;
  },
) {
  switch (action.type) {
    case "GET_COMPLETE_NUMBERS_SUCCESS": {
      return action.data;
    }
    default:
      return state;
  }
}
