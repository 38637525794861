import ReviewLinkSettingRes from "@/types/res/ReviewLinkSettingRes";

export type ReviewLinkSettingState = {
  reviewLinkSetting: ReviewLinkSettingRes;
};
const initialState = {} as ReviewLinkSettingState;
export default function reviewLinkSettingReducer(
  state = initialState,
  action: {
    type: string;
    data: ReviewLinkSettingState;
  },
) {
  switch (action.type) {
    case "GET_REVIEW_LINK_SETTING_SUCCESS":
      return action.data;
    case "UPDATE_REVIEW_LINK_SETTING_SUCCESS":
      return action.data;
    default:
      return state;
  }
}
