export type ProcessManagementReducerState = {
  beforeSaveProcessIdentifiers: string[];
  changeProcessProperty: string[];
  currentProcessIdentifier: string;
  isOnlyDisplayProcess: boolean;
};

const initialState: ProcessManagementReducerState = {
  beforeSaveProcessIdentifiers: [],
  changeProcessProperty: [],
  currentProcessIdentifier: "",
  isOnlyDisplayProcess: false,
};

export default function processManagementReducer(
  state = initialState,
  action: {
    type: string;
    beforeSaveProcessIdentifiers?: string[];
    changeProcessProperty?: string[];
    currentProcessIdentifier?: string;
    isOnlyDisplayProcess?: boolean;
  },
) {
  switch (action.type) {
    case "SET_BEFORE_SAVE_PROCESS_IDENTIFIERS": {
      return {
        ...state,
        beforeSaveProcessIdentifiers: [
          ...(action.beforeSaveProcessIdentifiers || []),
        ],
      };
    }
    case "RESET_BEFORE_SAVE_PROCESS_IDENTIFIERS": {
      return {
        ...state,
        beforeSaveProcessIdentifiers: [],
      };
    }
    case "SET_CHANGE_PROCESS_PROPERTY": {
      return {
        ...state,
        changeProcessProperty: [...(action.changeProcessProperty || [])],
      };
    }
    case "RESET_CHANGE_PROCESS_PROPERTY": {
      return {
        ...state,
        changeProcessProperty: [],
      };
    }
    case "SET_CURRENT_PROCESS_IDENTIFIER": {
      return {
        ...state,
        currentProcessIdentifier: action.currentProcessIdentifier || "",
      };
    }
    case "RESET_CURRENT_PROCESS_IDENTIFIER": {
      return {
        ...state,
        currentProcessIdentifier: "",
      };
    }
    case "SET_IS_ONLY_DISPLAY_PROCESS": {
      return {
        ...state,
        isOnlyDisplayProcess: action.isOnlyDisplayProcess || false,
      };
    }
    default:
      return state;
  }
}
