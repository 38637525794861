export type ReviewViewState = {
  openUploadNewFile: boolean;
};
const initialState: ReviewViewState = {
  openUploadNewFile: false,
};
export default function reviewViewReducer(
  state = initialState,
  action: {
    type: string;
    openUploadNewFile: boolean;
  },
) {
  switch (action.type) {
    case "OPEN_UPLOAD_NEW_FILE_MODAL": {
      return {
        ...state,
        openUploadNewFile: true,
      };
    }
    case "CLOSE_UPLOAD_NEW_FILE_MODAL": {
      return {
        ...state,
        openUploadNewFile: false,
      };
    }
    default:
      return state;
  }
}
