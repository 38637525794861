import ProjectRes from "@/types/res/ProjectRes";

export type ProjectState = {
  entities: {
    projects: { [key: number]: ProjectRes };
  };
  result: number[];
};

const initialState = {} as ProjectState;
export default function projectReducer(
  state = initialState,
  action: {
    type: string;
    data: ProjectState;
  },
) {
  switch (action.type) {
    case "GET_PROJECTS_SUCCESS":
      return action.data;
    case "GET_PROJECT_SUCCESS":
      return action.data;
    default:
      return state;
  }
}
