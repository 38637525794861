import UserCustomViewRes from "@/types/res/UserCustomViewRes";

export type ShareUserCustomViewConfigState = {
  entities: {
    shareUserCustomViewConfig: {
      [key: number]: UserCustomViewRes;
    };
  };
  result: number[];
};
const initialState = {} as ShareUserCustomViewConfigState;
export default function shareUserCustomViewConfigReducer(
  state = initialState,
  action: {
    type: string;
    data: ShareUserCustomViewConfigState;
  },
) {
  switch (action.type) {
    case "GET_SHARE_USER_CUSTOM_VIEW_CONFIG":
      return action.data;
    default:
      return state;
  }
}
