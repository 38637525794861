import ProcessSettingIconRes from "@/types/res/ProcessSettingIconRes";
export type ProcessSettingIconState = {
  entities: {
    processSettingIcon: { [key: string]: ProcessSettingIconRes };
  };
  result: number[];
};
const initialState = {
  entities: { processSettingIcon: {} },
  result: [],
} as ProcessSettingIconState;
export default function processSettingIconReducer(
  state = initialState,
  action: {
    type: string;
    data: ProcessSettingIconState;
    id: number;
  },
) {
  switch (action.type) {
    case "GET_PROCESS_SETTING_ICONS_SUCCESS": {
      return { ...action.data };
    }
    case "UPLOAD_PROCESS_SETTING_ICONS_SUCCESS": {
      return {
        entities: {
          processSettingIcon: {
            ...state.entities.processSettingIcon,
            ...action.data.entities.processSettingIcon,
          },
        },
        result: [...state.result, ...action.data.result],
      };
    }
    case "DELETE_PROCESS_SETTING_ICON_SUCCESS": {
      delete state.entities.processSettingIcon[action.id];
      return {
        entities: {
          processSettingIcon: state.entities.processSettingIcon,
        },
        result: state.result.filter((id) => id !== action.id),
      };
    }
    default:
      return state;
  }
}
