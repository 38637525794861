import CompleteTargetNumberRes from "@/types/res/CompleteTargetNumberRes";

export type CompleteTargetNumberState = {
  entities: {
    completeTargetNumbers: { [key: number]: CompleteTargetNumberRes };
  };
  result: number[];
};
const initialState = {} as CompleteTargetNumberState;
export default function completeTargetNumberReducer(
  state = initialState,
  action: {
    type: string;
    data: CompleteTargetNumberState;
  },
) {
  switch (action.type) {
    case "GET_COMPLETE_TARGET_NUMBERS_SUCCESS": {
      return action.data;
    }
    default:
      return state;
  }
}
