import CreativeRepositoryRes from "@/types/res/CreativeRepositoryRes";

export type CreativeRepositoryState = {
  entities: {
    creativeRepository?: CreativeRepositoryRes;
  };
};

const initialState = {} as CreativeRepositoryState;

export default function creativeRepositoryReducer(
  state = initialState,
  action: {
    type: string;
    creativeRepository: CreativeRepositoryRes;
  },
) {
  switch (action.type) {
    case "GET_CREATIVE_REPOSITORY_SUCCESS":
      return {
        ...state,
        entities: {
          ...state.entities,
          creativeRepository: action.creativeRepository,
        },
      };
    case "CLEAR_CREATIVE_REPOSITORY_SUCCESS":
      return {
        ...state,
        entities: {
          ...state.entities,
          creativeRepository: undefined,
        },
      };
    default:
      return state;
  }
}
