import ScheduleTaskEquipmentRes from "@/types/res/ScheduleTaskEquipmentRes";

export type ScheduleTaskEquipmentState = {
  entities: {
    scheduleTaskEquipments: { [key: number]: ScheduleTaskEquipmentRes };
  };
  result: number[];
};
const initialState = {} as ScheduleTaskEquipmentState;
export default function scheduleTaskEquipmentReducer(
  state = initialState,
  action: {
    type: string;
    data: ScheduleTaskEquipmentState;
    scheduleTaskEquipmentId: number;
  },
) {
  switch (action.type) {
    case "GET_SCHEDULE_TASK_EQUIPMENTS_SUCCESS":
      return action.data;
    case "MARK_SCHEDULE_TASK_EQUIPMENT_SUCCESS": {
      return {
        ...state,
        entities: {
          scheduleTaskEquipments: {
            ...state.entities.scheduleTaskEquipments,
            [action.scheduleTaskEquipmentId]: {
              ...state.entities.scheduleTaskEquipments[
                action.scheduleTaskEquipmentId
              ],
              status: "marked",
            },
          },
        },
      };
    }
    case "UNMARK_SCHEDULE_TASK_EQUIPMENT_SUCCESS": {
      return {
        ...state,
        entities: {
          scheduleTaskEquipments: {
            ...state.entities.scheduleTaskEquipments,
            [action.scheduleTaskEquipmentId]: {
              ...state.entities.scheduleTaskEquipments[
                action.scheduleTaskEquipmentId
              ],
              status: "unmarked",
            },
          },
        },
      };
    }
    default:
      return state;
  }
}
