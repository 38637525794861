import CustomNoteRes from "@/types/res/CustomNoteRes";

export type CustomNoteState = {
  customNote: CustomNoteRes;
};
const initialState: CustomNoteState = {
  customNote: {} as CustomNoteRes,
};
export default function customNoteReducer(
  state = initialState,
  action: {
    type: string;
    customNote: CustomNoteRes;
  },
) {
  switch (action.type) {
    case "GET_CUSTOM_NOTE_SUCCESS": {
      return {
        customNote: action.customNote,
      };
    }
    default:
      return state;
  }
}
