import CreativeFolderRes from "@/types/res/CreativeFolderRes";
import CreativeRepositoryRes from "@/types/res/CreativeRepositoryRes";

type CreativeFolderDataState = {
  entities: {
    creativeFolders: { [key: number]: CreativeFolderRes };
  };
  result: number[];
};
export type CreativeFolderState = {
  isLoading: boolean;
  evacuateRepository: CreativeRepositoryRes;
  evacuateVersionedRepository: CreativeRepositoryRes;
} & CreativeFolderDataState;

const initialState = {} as CreativeFolderState;

export default function creativeFolderReducer(
  state = initialState,
  action: {
    type: string;
    data: CreativeFolderDataState;
    baseFolderId: number;
    creativeFolderId: number;
    name: string;
    creativeFileId: number;
    title: string;
    thumbnail: string;
    creativeRepository: CreativeRepositoryRes;
    creativeRepositoryId: number;
  },
) {
  switch (action.type) {
    case "GET_CREATIVE_FOLDERS_SUCCESS":
      return {
        ...state,
        entities: { ...action.data.entities },
        result: [...action.data.result],
        isLoading: false,
      };
    case "GET_CREATIVE_FOLDERS_FAILURE":
      return {
        ...state,
        entities: { creativeFolders: {} },
        result: [],
        isLoading: false,
      };
    case "CREATE_CREATIVE_FOLDER_SUCCESS": {
      return {
        ...state,
        entities: {
          creativeFolders: {
            ...state.entities.creativeFolders,
            ...action.data.entities.creativeFolders,
          },
        },
        result: [...state.result, ...action.data.result],
      };
    }
    case "UPDATE_CREATIVE_FOLDER_SUCCESS": {
      return {
        ...state,
        entities: {
          creativeFolders: {
            ...state.entities.creativeFolders,
            [action.creativeFolderId]: {
              ...state.entities.creativeFolders[action.creativeFolderId],
              name: action.name,
            },
          },
        },
      };
    }
    case "DELETE_CREATIVE_FOLDER_SUCCESS": {
      delete state.entities.creativeFolders[action.creativeFolderId];
      return {
        ...state,
        entities: {
          creativeFolders: { ...state.entities.creativeFolders },
        },
        result: state.result.filter((id) => id !== action.creativeFolderId),
      };
    }
    case "CREATE_CREATIVE_FILE_SUCCESS": {
      if (!Object.keys(state).length) return state;
      const repository = action.creativeRepository;
      return {
        ...state,
        entities: {
          creativeFolders: {
            ...state?.entities?.creativeFolders,
            [repository.creativeFolderId]: {
              ...state?.entities?.creativeFolders[repository.creativeFolderId],
              creativeRepositories: [
                ...(state?.entities?.creativeFolders[
                  repository.creativeFolderId
                ]?.creativeRepositories || []),
                repository,
              ],
            },
          },
        },
      };
    }
    case "VERSION_UP_CREATIVE_FILE_SUCCESS": {
      if (!Object.keys(state).length) return state;
      const repository = action.creativeRepository;
      const creativeRepositories = state.entities.creativeFolders[
        repository.creativeFolderId
      ].creativeRepositories.map((repo) => {
        return repo.id === repository.id ? repository : repo;
      });
      return {
        ...state,
        entities: {
          creativeFolders: {
            ...state?.entities?.creativeFolders,
            [repository.creativeFolderId]: {
              ...state?.entities?.creativeFolders[repository.creativeFolderId],
              creativeRepositories: creativeRepositories,
            },
          },
        },
      };
    }
    case "UPLOAD_TO_EMPTY_CREATIVE_FILE_SUCCESS": {
      const repository = action.creativeRepository;
      const creativeRepositories = (
        state?.entities?.creativeFolders[repository.creativeFolderId]
          ?.creativeRepositories || []
      ).map((repo) => {
        return repo.id === repository.id ? repository : repo;
      });
      return {
        ...state,
        entities: {
          creativeFolders: {
            ...state?.entities?.creativeFolders,
            [repository.creativeFolderId]: {
              ...state?.entities?.creativeFolders[repository.creativeFolderId],
              creativeRepositories: creativeRepositories,
            },
          },
        },
      };
    }
    case "EMPTY_CREATIVE_FILE_SUCCESS": {
      const repository = action.creativeRepository;
      const creativeRepositories = (
        state?.entities?.creativeFolders[repository.creativeFolderId]
          ?.creativeRepositories || []
      ).map((repo) => {
        return repo.id === repository.id ? repository : repo;
      });
      return {
        ...state,
        entities: {
          creativeFolders: {
            ...state?.entities?.creativeFolders,
            [repository.creativeFolderId]: {
              ...state?.entities?.creativeFolders[repository.creativeFolderId],
              creativeRepositories: creativeRepositories,
            },
          },
        },
      };
    }
    case "UPDATE_CREATIVE_FILE_SUCCESS": {
      const creativeRepositories = state.entities.creativeFolders[
        action.creativeFolderId
      ].creativeRepositories.map((creativeRepository) => {
        if (creativeRepository.id === action.creativeRepositoryId) {
          const creativeFiles = creativeRepository.creativeFiles.map(
            (creativeFile) => {
              if (creativeFile.id === action.creativeFileId) {
                return {
                  ...creativeFile,
                  title: action.title,
                  thumbnail: action.thumbnail,
                };
              } else {
                return creativeFile;
              }
            },
          );
          return {
            ...creativeRepository,
            creativeFiles,
          };
        } else {
          return creativeRepository;
        }
      });
      return {
        ...state,
        entities: {
          creativeFolders: {
            ...state.entities.creativeFolders,
            [action.creativeFolderId]: {
              ...state.entities.creativeFolders[action.creativeFolderId],
              creativeRepositories,
            },
          },
        },
      };
    }
    case "EVACUATE_CREATIVE_REPOSITORY": {
      return {
        ...state,
        entities: {
          creativeFolders: {
            ...state.entities.creativeFolders,
            [action.baseFolderId]: {
              ...state.entities.creativeFolders[action.baseFolderId],
              creativeRepositories: state.entities.creativeFolders[
                action.baseFolderId
              ].creativeRepositories.filter(
                (creativeRepository) =>
                  creativeRepository.id !== action.creativeRepositoryId,
              ),
            },
          },
        },
        evacuateRepository: state.entities.creativeFolders[
          action.baseFolderId
        ].creativeRepositories.find(
          (repo) => repo.id === action.creativeRepositoryId,
        ),
      };
    }
    case "MOVE_CREATIVE_REPOSITORY_SUCCESS": {
      return {
        ...state,
        entities: {
          creativeFolders: {
            ...state.entities.creativeFolders,
            [action.creativeFolderId]: {
              ...state.entities.creativeFolders[action.creativeFolderId],
              creativeRepositories: [
                ...state.entities.creativeFolders[action.creativeFolderId]
                  .creativeRepositories,
                state.evacuateRepository,
              ],
            },
          },
        },
        evacuateRepository: null,
      };
    }
    case "EVACUATE_VERSIONED_CREATIVE_REPOSITORY": {
      return {
        ...state,
        entities: {
          creativeFolders: {
            ...state.entities.creativeFolders,
            [action.creativeFolderId]: {
              ...state.entities.creativeFolders[action.creativeFolderId],
              creativeRepositories: state.entities.creativeFolders[
                action.creativeFolderId
              ].creativeRepositories.filter(
                (creativeRepository) =>
                  creativeRepository.id !== action.creativeRepositoryId,
              ),
            },
          },
        },
        evacuateVersionedRepository: state.entities.creativeFolders[
          action.creativeFolderId
        ].creativeRepositories.find(
          (repo) => repo.id === action.creativeRepositoryId,
        ),
      };
    }
    case "VERSION_UP_SUCCESS": {
      return {
        ...state,
        entities: {
          creativeFolders: {
            ...state.entities.creativeFolders,
            [action.creativeFolderId]: {
              ...state.entities.creativeFolders[action.creativeFolderId],
              creativeRepositories: state.entities.creativeFolders[
                action.creativeFolderId
              ].creativeRepositories.map((repo) => {
                if (repo.id === action.creativeRepositoryId) {
                  const files = repo.creativeFiles.sort(
                    (a, b) => b.version - a.version,
                  );
                  const file =
                    state.evacuateVersionedRepository.creativeFiles[0];
                  file.version = Number(files[0].version) + 1;
                  file.creativeRepositoryId = repo.id;
                  files.push(file);
                  return {
                    ...repo,
                    creativeFiles: files,
                  };
                }
                return repo;
              }),
            },
          },
        },
        evacuateVersionedRepository: null,
      };
    }
    default:
      return state;
  }
}
