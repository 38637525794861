import { ProjectProcessRes } from "@/types/res/PlanBoxProjectRes";
import TodoRes from "@/types/res/TodoRes";

export type ProjectStatusState = {
  entities: {
    todos: { [key: number]: TodoRes };
    process: { [key: string]: ProjectProcessRes };
  };
  result: number[];
  warningTodos: number[];
  checkTodos: number[];
  lookTodos: number[];
  isAcceptedReview: boolean;
  isPresentArchiveRecommended: boolean;
};
const initialState = {} as ProjectStatusState;
export default function projectStatusReducer(
  state = initialState,
  action: {
    type: string;
    data: ProjectStatusState;
    iconType: "warning" | "check" | "look";
    isAcceptedReview: boolean;
    isPresentArchiveRecommended: boolean;
  },
) {
  switch (action.type) {
    case "GET_TODOS_SUCCESS":
      if (action.iconType === "warning") {
        return {
          ...state,
          entities: {
            todos: {
              ...state.entities?.todos,
              ...action.data.entities.todos,
            },
            process: {
              ...state.entities?.process,
              ...action.data.entities.process,
            },
          },
          result: [],
          warningTodos: action.data.result,
        };
      } else if (action.iconType === "check") {
        return {
          ...state,
          entities: {
            todos: {
              ...state.entities?.todos,
              ...action.data.entities.todos,
            },
            process: {
              ...state.entities?.process,
              ...action.data.entities.process,
            },
          },
          result: [],
          checkTodos: action.data.result,
        };
      } else if (action.iconType === "look") {
        return {
          ...state,
          entities: {
            todos: {
              ...state.entities?.todos,
              ...action.data.entities.todos,
            },
            process: {
              ...state.entities?.process,
              ...action.data.entities.process,
            },
          },
          result: [],
          lookTodos: action.data.result,
        };
      } else {
        return state;
      }
    case "GET_IS_ACCEPTED_REVIEW_SUCCESS":
      return {
        ...state,
        isAcceptedReview: action.isAcceptedReview,
      };
    case "GET_IS_PRESENT_ARCHIVE_RECOMMENDED_SUCCESS":
      return {
        ...state,
        isPresentArchiveRecommended: action.isPresentArchiveRecommended,
      };
    default:
      return state;
  }
}
