import ShareIdeaBoxRes from "@/types/res/ShareIdeaBoxRes";

export type ShareIdeaBoxState = {
  shareIdeaBox: ShareIdeaBoxRes;
};
const initialState = {} as ShareIdeaBoxState;
export default function shareIdeaBoxReducer(
  state = initialState,
  action: {
    type: string;
    data: ShareIdeaBoxState;
  },
) {
  switch (action.type) {
    case "GET_SHARE_IDEA_BOX_SUCCESS":
      return action.data;
    default:
      return state;
  }
}
