import CreativeFileRes from "@/types/res/CreativeFileRes";

export type SoftEmptyCreativeFileState = {
  entities: {
    creativeFiles: { [key: number]: CreativeFileRes };
  };
  result: number[];
};

const initialState = {} as SoftEmptyCreativeFileState;

export default function softEmptyCreativeFileReducer(
  state = initialState,
  action: {
    type: string;
    key: string;
    value: string;
    data: SoftEmptyCreativeFileState;
  },
) {
  switch (action.type) {
    case "GET_SOFT_EMPTIED_CREATIVE_FILES_SUCCESS":
      return {
        ...state,
        entities: action.data.entities,
        result: action.data.result,
      };
    default:
      return state;
  }
}
