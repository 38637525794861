import KpiSummaryRes from "@/types/res/KpiSummaryRes";

export type KpiSummaryState = {
  entities: {
    kpiSummaries: { [key: number]: KpiSummaryRes };
  };
  result: number[];
};

const initialState = {} as KpiSummaryState;
export default function kpiSummaryReducer(
  state = initialState,
  action: {
    type: string;
    data: KpiSummaryState;
  },
) {
  switch (action.type) {
    case "SEARCH_KPI_SUMMARY_SUCCESS":
      return action.data;
    default:
      return state;
  }
}
