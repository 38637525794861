import CreativeFileRes from "@/types/res/CreativeFileRes";

export type CreativeFileVersionState = {
  entities: {
    creativeFiles: { [key: number]: CreativeFileRes };
  };
  result: number[];
};

const initialState = {} as CreativeFileVersionState;

export default function creativeFileVersionReducer(
  state = initialState,
  action: {
    type: string;
    data: CreativeFileVersionState;
  },
) {
  switch (action.type) {
    case "GET_VERSIONS_SUCCESS":
      return action.data;
    default:
      return state;
  }
}
