import JoinRequestRes from "@/types/res/JoinRequestRes";

type JoinRequestDataState = {
  entities: {
    joinRequests: { [key: number]: JoinRequestRes };
  };
  result: number[];
};
export type JoinRequestState = {
  joinRequestIds: number[];
  teamJoinRequestIds: number[];
} & JoinRequestDataState;
const initialState = {} as JoinRequestState;
export default function joinRequestReducer(
  state = initialState,
  action: {
    type: string;
    data: JoinRequestState;
  },
) {
  switch (action.type) {
    case "GET_JOIN_REQUESTS_SUCCESS":
      return {
        ...state,
        entities: {
          joinRequests: {
            ...state.entities?.joinRequests,
            ...action.data.entities.joinRequests,
          },
        },
        joinRequestIds: [...action.data.result],
      };
    case "GET_TEAM_JOIN_REQUESTS_SUCCESS":
      return {
        ...state,
        entities: {
          joinRequests: {
            ...state.entities?.joinRequests,
            ...action.data.entities.joinRequests,
          },
        },
        teamJoinRequestIds: [...action.data.result],
      };
    default:
      return state;
  }
}
