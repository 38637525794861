import React, { Suspense } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import PageLoading from "@/components/PageLoading";
import lazyWithRetry from "@/shared/libs/LazyWithRetry";
import rootReducer from "@/user/reducers/rootReducer";
const SigninApp = lazyWithRetry(
  () => import(/* webpackChunkName: "SigninApp" */ "./SigninApp"),
);
const UserApp = lazyWithRetry(
  () => import(/* webpackChunkName: "UserApp" */ "./UserApp"),
);

export const store = configureStore({
  reducer: rootReducer,
  devTools: !import.meta.env.PROD,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

function App() {
  const { isAuthenticated } = useAuth0();

  const params = new URL(`${window.location}`).searchParams;
  const ott = params.get("ott");

  const review =
    window.location.pathname.match(
      /^\/app\/reviews\/[1-9]+[0-9]*\/creative/g,
    ) && ott;
  const shareUserCustomView =
    window.location.pathname.match(/^\/app\/share_user_custom_view/g) && ott;
  const shareIdeaBoxLink =
    window.location.pathname.match(/^\/app\/share_idea_box/g) && ott;
  const creativeFileDownloadLink =
    window.location.pathname.match(/^\/app\/creative_file_download_link/g) &&
    ott;

  return (
    <Provider store={store}>
      {isAuthenticated && window.location.pathname.match(/^\/app.*/g) ? (
        <Suspense fallback={<PageLoading />}>
          <UserApp />
        </Suspense>
      ) : review ||
        shareUserCustomView ||
        shareIdeaBoxLink ||
        creativeFileDownloadLink ||
        (window.location.pathname.match(/^\/app\/uploads/) && ott) ? (
        <Suspense fallback={<PageLoading />}>
          <UserApp />
        </Suspense>
      ) : (
        <Suspense fallback={<PageLoading />}>
          <SigninApp />
        </Suspense>
      )}
    </Provider>
  );
}

export default App;
