import RoleRes from "@/types/res/RoleRes";

export type RoleState = {
  entities: {
    roles: { [key: number]: RoleRes };
  };
  result: number[];
};
const initialState = {} as RoleState;
export default function roleReducer(
  state = initialState,
  action: {
    type: string;
    data: RoleState;
  },
) {
  switch (action.type) {
    case "GET_ROLES_SUCCESS":
      return action.data;
    default:
      return state;
  }
}
