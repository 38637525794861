import UserGroupRes from "@/types/res/UserGroupRes";

export type UserGroupState = {
  entities: { userGroups: { [key: number]: UserGroupRes } };
  result: number[];
};
const initialState = {} as UserGroupState;
export default function userGroupReducer(
  state = initialState,
  action: {
    type: string;
    data: UserGroupState;
    id: number;
  },
) {
  switch (action.type) {
    case "GET_USER_GROUPS_SUCCESS":
      return action.data;
    case "CREATE_USER_GROUP_SUCCESS": {
      return {
        entities: {
          userGroups: {
            ...state.entities.userGroups,
            ...action.data.entities.userGroups,
          },
        },
        result: [...action.data.result, ...state.result],
      };
    }
    case "UPDATE_USER_GROUP_SUCCESS": {
      return {
        entities: {
          userGroups: {
            ...state.entities.userGroups,
            ...action.data.entities.userGroups,
          },
        },
        result: state.result,
      };
    }
    case "DESTROY_USER_GROUP_SUCCESS": {
      delete state.entities.userGroups[action.id];
      return {
        ...state,
        entities: {
          userGroups: { ...state.entities.userGroups },
        },
        result: state.result.filter((id) => id !== action.id),
      };
    }
    default:
      return state;
  }
}
