import PlanBoxProjectRes from "@/types/res/PlanBoxProjectRes";

export type ShareUserCustomViewProjectState = {
  entities: {
    planBoxProjects: { [key: number]: PlanBoxProjectRes };
  };
  result: number[];
};

const initialState = {} as ShareUserCustomViewProjectState;
export default function shareUserCustomViewProjectReducer(
  state = initialState,
  action: {
    type: string;
    data: ShareUserCustomViewProjectState;
  },
) {
  switch (action.type) {
    case "GET_SHARE_USER_CUSTOM_VIEW_PROJECTS_SUCCESS": {
      return action.data;
    }
    default:
      return state;
  }
}
