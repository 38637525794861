import ReviewCsvDownloadSettingRes from "@/types/res/ReviewCsvDownloadSettingRes";

export type ReviewCsvDownloadSettingState = {
  reviewCsvDownloadSetting: ReviewCsvDownloadSettingRes;
};
const initialState = {} as ReviewCsvDownloadSettingState;
export default function reviewCsvDownloadSettingReducer(
  state = initialState,
  action: {
    type: string;
    data: ReviewCsvDownloadSettingState;
  },
) {
  switch (action.type) {
    case "GET_REVIEW_CSV_DOWNLOAD_SETTING_SUCCESS":
      return action.data;
    case "UPDATE_REVIEW_CSV_DOWNLOAD_SETTING_SUCCESS":
      return action.data;
    default:
      return state;
  }
}
