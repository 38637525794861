import React from "react";
import styled from "styled-components";
import COLORs from "@/shared/libs/color";
import { FONT_SIZE, FONT_WEIGHT } from "@/shared/libs/constants";

const StyledText = styled.p<{
  color?: ValueOf<typeof COLORs> | string;
  weight?: ValueOf<typeof FONT_WEIGHT>;
  size?: ValueOf<typeof FONT_SIZE> | number;
}>`
  font-size: ${(props) => `${props.size}rem`};
  font-weight: ${(props) => props.weight};
  color: ${(props) => props.color};
`;

type Props = {
  text: string;
  color?: ValueOf<typeof COLORs> | string;
  weight?: ValueOf<typeof FONT_WEIGHT>;
  size?: ValueOf<typeof FONT_SIZE> | number;
  className?: string;
  style?: Record<string, unknown>;
} & React.HTMLAttributes<HTMLParagraphElement>;
const Text: React.FC<Props> = (props: Props) => {
  return <StyledText {...props}>{props.text}</StyledText>;
};
export default Text;
