import BlankPostedAtProjectCountRes from "@/types/res/BlankPostedAtProjectCountRes";

export type BlankPostedAtProjectCountState = {
  entities: {
    blankPostedAtProjectCounts: { [key: number]: BlankPostedAtProjectCountRes };
  };
  result: number[];
};

const initialState = {} as BlankPostedAtProjectCountState;
export default function blankPostedAtProjectCountReducer(
  state = initialState,
  action: {
    type: string;
    data: BlankPostedAtProjectCountState;
  },
) {
  switch (action.type) {
    case "GET_BLANK_POSTED_AT_PROJECTS_COUNT_SUCCESS":
      return action.data;
    default:
      return state;
  }
}
