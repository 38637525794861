import PluginSettingRes from "@/types/res/PluginSettingRes";

export type PluginSettingState = {
  pluginSetting: PluginSettingRes;
};
const initialState: PluginSettingState = {
  pluginSetting: {} as PluginSettingRes,
};
export default function pluginSettingReducer(
  state = initialState,
  action: {
    type: string;
    pluginSetting: PluginSettingRes;
  },
) {
  switch (action.type) {
    case "GET_PLUGIN_SETTING_SUCCESS": {
      return {
        pluginSetting: action.pluginSetting,
      };
    }
    case "UPDATE_PLUGIN_SETTING_SUCCESS": {
      return {
        pluginSetting: action.pluginSetting,
      };
    }
    default:
      return state;
  }
}
