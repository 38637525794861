import ShareIdeaBoxSettingRes from "@/types/res/ShareIdeaBoxSettingRes";

export type ShareIdeaBoxSettingState = {
  shareIdeaBoxSetting: ShareIdeaBoxSettingRes;
};
const initialState = {} as ShareIdeaBoxSettingState;
export default function shareIdeaBoxSettingReducer(
  state = initialState,
  action: {
    type: string;
    data: ShareIdeaBoxSettingState;
  },
) {
  switch (action.type) {
    case "GET_SHARE_IDEA_BOX_SETTING_SUCCESS":
      return action.data;
    case "UPDATE_SHARE_IDEA_BOX_SETTING_SUCCESS":
      return action.data;
    default:
      return state;
  }
}
