import EquipmentRes from "@/types/res/EquipmentRes";
import EquipmentReservationRes from "@/types/res/EquipmentReservationRes";
import PartnerRes from "@/types/res/PartnerRes";
import UserRes from "@/types/res/UserRes";

export type EquipmentReservationSelectionState = {
  entities: {
    equipmentReservations: { [key: number]: EquipmentReservationRes };
    equipments: { [key: number]: EquipmentRes };
    users: { [key: number]: UserRes };
    partners: { [key: number]: PartnerRes };
  };
  result: number[];
};
const initialState = {} as EquipmentReservationSelectionState;
export default function equipmentReservationSelectionReducer(
  state = initialState,
  action: {
    type: string;
    data: EquipmentReservationSelectionState;
  },
) {
  switch (action.type) {
    case "GET_RESERVED_EQUIPMENT_SELECTIONS_SUCCESS":
      return action.data;
    case "CLEAR_EQUIPMENT_SELECTIONS": {
      return initialState;
    }
    default:
      return state;
  }
}
